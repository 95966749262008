<template>
  <div id="RecipeAdvancedEditForPet" class="w-full overflow-x-auto">

    <div class="container mx-auto pt-10 px6">
      <div class="w-1/6">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-1
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goToEditRecipe()">
          Back
        </button>
      </div>
    </div>

    <div id="ListPets" class="container mx-auto pt-5 pb-20 px6">
      <div class="text-gray-900 text-3xl text-center">{{ recipe.name }} for {{ pet.name }}</div>
      <div class="bg-innogreen w-full h-1 my-2"></div>
      <div class="text-center">
        <label class="relative inline-flex items-center cursor-pointer ml-2 mr-8">
          <input type="checkbox" value="true" class="sr-only peer" v-model="optimizeForEnergy">
          <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-innogreen dark:peer-focus:ring-innogreen dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-innogreen"></div>
          <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Adjust serving size to meet caloric needs</span>
        </label>
      </div>
      <div class="text-gray-900 text-xl text-center">This page is intended to be used on a large desktop or iPad screen.</div>
      <div class="text-gray-900 text-xl text-center">{{ pet.name }} <span class="font-bold">serving size = {{ petGramsPerDayRounded }} grams (approx. {{ petCupsPerDayText }}) of food</span>, divided into 2-3 meals per day. That amount of the recipe will result in the following nutritional profile for {{ pet.name }}.</div>
      <div v-if="servingSizeExceeds925PercentOfBodyWeight" class="text-gray-900 text-xl text-center mt-3">
        <span class="font-bold">WARNING:</span> This serving size exceeds 9.25% of your pets body weight because the recipe does not have enough calories to meet your pets caloric need. Please select a higher calorie recipe or edit the recipe accordingly. You can adjust the caloric need of your pet by adjusting the activity level in the pet profile.
      </div>
    </div>
    
    <div class="flex min-w-[2600px]">
        <!-- Column 1 -->
        <div v-if="recipe.ingredients.length > 0" class="flex-shrink-0 min-w-[500px] border-l-4 border-y-4 border-gray-500">
          <div class="text-gray-900 text-2xl text-left px-2 py-1">
            Recipe Editor
            <button v-if="!saving" @click="editRecipe" type="button" class="ml-2 text-white bg-innoorange hover:bg-innoorange-hover focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1.5 dark:bg-blue-600 dark:hover:bg-innoorange dark:focus:ring-innoorange-hover">Save Changes &amp; Re-Calculate</button>
            <button v-if="saving" disabled type="button" class="ml-2 text-white bg-innoorange hover:bg-innoorange-hover focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1.5 dark:bg-blue-600 dark:hover:bg-innoorange dark:focus:ring-innoorange-hover">Processing</button>
          </div>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg max-h-[900px]">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead class="sticky top-0 text-xs text-white uppercase bg-innogreen">
                      <tr>
                          <th scope="col" class="px-3 py-1">
                              Ingredient name
                          </th>
                          <th scope="col" class="px-3 py-1">
                              Grams
                          </th>
                          <th scope="col" class="px-3 py-1">
                              Notes
                          </th>
                          <th scope="col" class="px-3 py-1">
                              Action
                          </th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                        <th scope="row" class="px-3 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          <Popper :content="txtMaxBatch" :disabled="txtMaxBatch < colMaxChars">
                            {{ $filters.strLimit(txtMaxBatch, colMaxChars) }}
                          </Popper>
                        </th>
                        <td class="px-3 py-1" :class="{ 'text-red-500': ingredientGramsDontMatchRecipe }">
                          {{ totalDisplay }}
                        </td>
                        <td class="px-3 py-1">
                          &nbsp;
                        </td>
                        <td class="px-3 py-1">
                          &nbsp;
                        </td>
                      </tr>
                      <tr v-for="(ingredient, index) in recipe.ingredients" :key="recipeIngredientKey(recipe, ingredient)"
                        :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                          <th scope="row" class="px-3 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            <Popper :content="ingredient.name" :disabled="ingredient.name < colMaxChars">
                              {{ $filters.strLimit(ingredient.name, colMaxChars) }}
                            </Popper>
                          </th>
                          <td class="px-3 py-1">
                            <input type="text" class="w-[100px]" v-model="ingredient.amount.total_g" @keyup.enter="editRecipe">
                          </td>
                          <td class="px-3 py-1">
                            <a v-if="ingredient.unsafe" @click="showUnsafe(ingredient)"><i class="fa-solid fa-skull-crossbones fa-xl text-red-500 pr-4"></i></a>
                            <a v-if="ingredient.possibly_unsafe" @click="showPossiblyUnsafe(ingredient)"><i class="fa-solid fa-triangle-exclamation fa-xl text-innoorange pr-4"></i></a>
                            <span v-if="ingredient.notes.length > 0">
                              <a v-if="ingredient.notes.length > 0" @click="showIngredientNotes(ingredient)"><i class="fa-solid fa-comment-dots fa-xl text-innogreen pr-4"></i></a>
                              {{ $filters.strLimit(ingredient.notes[0].msg, 12) }}
                            </span>
                          </td>
                          <td class="px-3 py-1">
                            <div v-if="!ingredient.loading">
                              <a @click="removeIngredientAtIndex(index)" href="javascript:void(0)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Remove</a>
                            </div>
                            <div v-if="ingredient.loading" class="spinner-border animate-spin inline-block w-4 h-4 rounded-full" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>

          <div class="bg-innogreen w-full h-1"></div>

          <div class="text-gray-900 text-xl max-w-[500px]">Add Ingredients to {{ recipe.name }}</div>
          
          <div class="pt-5 pb-5">
            <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
            <div class="relative">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                </div>
                <input v-model="query" @keyup.enter="searchIngredients" type="text" class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search ChefPaw Ingredients..." required>
                <button v-if="!searching" @click="searchIngredients" type="button" class="text-white absolute right-2.5 bottom-2.5 bg-innoorange hover:bg-innoorange-hover focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 dark:bg-blue-600 dark:hover:bg-innoorange dark:focus:ring-innoorange-hover">Search</button>
                <button v-if="searching" type="button" class="text-white absolute right-2.5 bottom-2.5 bg-innoorange hover:bg-innoorange-hover focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 dark:bg-blue-600 dark:hover:bg-innoorange dark:focus:ring-innoorange-hover">
                  <div class="spinner-border animate-spin inline-block w-4 h-4 rounded-full" role="status">
                    <svg class="w-5 h-5 animate-spin ml-auto mr-auto" fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path class="opacity-75"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            fill="currentColor"></path>
                    </svg>
                  </div>
                </button>
            </div>
          </div>

          <div v-if="ingredients.length > 0">
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-3 py-1">
                                Ingredient name
                            </th>
                            <th scope="col" class="px-2 py-1">
                                Notes
                            </th>
                            <th scope="col" class="px-3 py-1">
                                Grams
                            </th>
                            <th scope="col" class="px-3 py-1">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(ingredient, index) in ingredients" :key="ingredient.id"
                          :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                            <th scope="row" class="px-3 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              {{ ingredient.name }}
                            </th>
                            <td class="px-2 py-1">
                              <a v-if="ingredient.possibly_unsafe" @click="showPossiblyUnsafe(ingredient)"><i class="fa-solid fa-triangle-exclamation fa-xl text-innoorange pr-4"></i></a>
                              <span v-if="ingredient.notes.length > 0">
                                <a @click="showIngredientNotes(ingredient)"><i class="fa-solid fa-comment-dots fa-xl text-innogreen pr-4"></i></a>
                                {{ $filters.strLimit(ingredient.notes[0].msg, 12) }}
                              </span>
                            </td>
                            <td class="px-3 py-1">
                              <input type="text" v-model="ingredient.total_g">
                            </td>
                            <td class="px-3 py-1">
                              <div v-if="!ingredient.loading">
                                <a v-if="ingredient.unsafe" @click="showUnsafe(ingredient)"><i class="fa-solid fa-skull-crossbones fa-xl text-red-500 pr-4"></i></a>
                                <a v-if="!ingredient.unsafe && !ingredient.added" @click="addIngredient(ingredient)" href="javascript:void(0)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Add</a>
                                <span v-if="ingredient.added" class="text-green">Added</span>
                              </div>
                              <div v-if="ingredient.loading" class="spinner-border animate-spin inline-block w-4 h-4 rounded-full" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                              <div v-if="false" class="flex justify-center items-center">
                                <div class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
          </div>
        </div>
        
        <!-- Column 2 -->
        <div v-if="pet.daily_nutrition_breakdown" class="flex-shrink-0 min-w-[400px] border-4 border-gray-500">
          <div class="text-gray-900 text-2xl text-left p-2">
            <div v-if="pets.length > 0 && pet.name != '' && recipe.name != ''" class="mx-2">
              Pet Profile Analyzer
              <select class="
                      appearance-none
                      bg-white
                      text-gray-700
                      border
                      border-gray-400
                      rounded
                      py-1
                      px-2
                      mb-2
                      leading-tight
                      focus:outline-none
                      focus:bg-white
                      focus:border-gray-500
                      align-middle
                      "
                      v-model="selPetId"
                      @change="selPetChanged()"
              >
                <option v-for="p in pets" :key="p.id" :selected="pet.id == selPetId" :value="p.id">{{ p.name }}</option>
              </select>
            </div>
          </div>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg max-h-[900px]">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="sticky top-0 text-xs text-white uppercase bg-innogreen">
                    <tr>
                        <th scope="col" class="px-2 py-1">
                            Nutrient
                        </th>
                        <th scope="col" class="px-2 py-1">
                            Daily<br>Need
                        </th>
                        <th scope="col" class="px-2 py-1">
                            Amt Per<br>Serving
                        </th>
                        <th scope="col" class="px-2 py-1">
                            Nutrient<br>Level
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                      <td class="px-2 py-1">&nbsp;</td>
                      <td class="px-2 py-1">&nbsp;</td>
                      <td class="px-2 py-1">{{ petGramsPerDayRounded }}g</td>
                      <td class="px-2 py-1">&nbsp;</td>
                    </tr>
                    <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">MACROS</th>
                      <td class="px-2 py-1">&nbsp;</td>
                      <td class="px-2 py-1">&nbsp;</td>
                      <td class="px-2 py-1">&nbsp;</td>
                    </tr>
                    <tr v-for="(nutrientName, index) in petProfileAnalyzerMacroItems" :key="petProfileAnalyzerMacroItemKey(index)"
                      :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                        <th scope="row" class="md:hidden px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                          <Popper :content="recipeModel.nutrientNameFormatted(nutrientName, true)" :disabled="recipeModel.nutrientNameFormatted(nutrientName, true).length < colMaxChars">
                            {{ $filters.strLimit(recipeModel.nutrientNameFormatted(nutrientName, true), colMaxChars) }}
                          </Popper>
                        </th>
                        <th scope="row" class="hidden md:table-cell px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                          <Popper :content="recipeModel.nutrientNameFormatted(nutrientName, true)" :disabled="recipeModel.nutrientNameFormatted(nutrientName, true).length < colMaxChars">
                            {{ $filters.strLimit(recipeModel.nutrientNameFormatted(nutrientName, true), colMaxChars) }}
                          </Popper>
                        </th>
                        <td class="px-2 py-1">
                          {{ petNutrientDisplayAmount(nutrientName) }}
                        </td>
                        <td class="px-2 py-1">
                          {{ recipeNutrientDisplayAmount(nutrientName) }}
                        </td>
                        <td class="px-2 py-1" :class="[rangeClassByNutrientName(nutrientName)]">
                          {{ rangeLabelByNutrientName(nutrientName) }}
                        </td>
                    </tr>
                    <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">MINERALS</th>
                      <td class="px-2 py-1">&nbsp;</td>
                      <td class="px-2 py-1">&nbsp;</td>
                      <td class="px-2 py-1">&nbsp;</td>
                    </tr>
                    <tr v-for="(nutrientName, index) in petProfileAnalyzerMineralItems" :key="petProfileAnalyzerMineralItemKey(index)"
                      :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                        <th scope="row" class="md:hidden px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                          <Popper :content="recipeModel.nutrientNameFormatted(nutrientName, true)" :disabled="recipeModel.nutrientNameFormatted(nutrientName, true).length < colMaxChars">
                            {{ $filters.strLimit(recipeModel.nutrientNameFormatted(nutrientName, true), colMaxChars) }}
                          </Popper>
                        </th>
                        <th scope="row" class="hidden md:table-cell px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                          <Popper :content="recipeModel.nutrientNameFormatted(nutrientName, true)" :disabled="recipeModel.nutrientNameFormatted(nutrientName, true).length < colMaxChars">
                            {{ $filters.strLimit(recipeModel.nutrientNameFormatted(nutrientName, true), colMaxChars) }}
                          </Popper>
                        </th>
                        <td class="px-2 py-1">
                          {{ petNutrientDisplayAmount(nutrientName) }}
                        </td>
                        <td class="px-2 py-1">
                          {{ recipeNutrientDisplayAmount(nutrientName) }}
                        </td>
                        <td class="px-2 py-1" :class="[rangeClassByNutrientName(nutrientName)]">
                          {{ rangeLabelByNutrientName(nutrientName) }}
                        </td>
                    </tr>
                    <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">VITAMINS</th>
                      <td class="px-2 py-1">&nbsp;</td>
                      <td class="px-2 py-1">&nbsp;</td>
                      <td class="px-2 py-1">&nbsp;</td>
                    </tr>
                    <tr v-for="(nutrient, index) in petNutrientsVitamins" :key="petNutrientVitaminKey(nutrient)"
                      :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                        <th scope="row" class="md:hidden px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                          <Popper :content="recipeModel.nutrientNameFormatted(nutrient.name, true)" :disabled="recipeModel.nutrientNameFormatted(nutrient.name, true).length < colMaxChars">
                            {{ $filters.strLimit(recipeModel.nutrientNameFormatted(nutrient.name, true), colMaxChars) }}
                          </Popper>
                        </th>
                        <th scope="row" class="hidden md:table-cell px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                          <Popper :content="recipeModel.nutrientNameFormatted(nutrient.name, true)" :disabled="recipeModel.nutrientNameFormatted(nutrient.name, true).length < colMaxChars">
                            {{ $filters.strLimit(recipeModel.nutrientNameFormatted(nutrient.name, true), colMaxChars) }}
                          </Popper>
                        </th>
                        <td class="px-2 py-1">
                          {{ petNutrientDisplayAmount(nutrient.name) }}
                        </td>
                        <td class="px-2 py-1">
                          {{ recipeNutrientDisplayAmount(nutrient.name) }}
                        </td>
                        <td class="px-2 py-1" :class="[rangeClassByNutrientName(nutrient.name)]">
                          {{ rangeLabelByNutrientName(nutrient.name) }}
                        </td>
                    </tr>
                    <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                      <th class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">FATS</th>
                      <td class="px-2 py-1">&nbsp;</td>
                      <td class="px-2 py-1">&nbsp;</td>
                      <td class="px-2 py-1">&nbsp;</td>
                    </tr>
                    <tr v-for="(nutrient, index) in petNutrientsFats" :key="petNutrientFatKey(nutrient)"
                      :class="index % 2 == 0 ? 'bg-white border-b dark:bg-gray-900 dark:border-gray-700' : 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700'">
                        <th scope="row" class="md:hidden px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                          <Popper :content="recipeModel.nutrientNameFormatted(nutrient.name, true)" :disabled="recipeModel.nutrientNameFormatted(nutrient.name, true).length < colMaxChars">
                            {{ $filters.strLimit(recipeModel.nutrientNameFormatted(nutrient.name, true), colMaxChars) }}
                          </Popper>
                        </th>
                        <th scope="row" class="hidden md:table-cell px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                          <Popper :content="recipeModel.nutrientNameFormatted(nutrient.name, true)" :disabled="recipeModel.nutrientNameFormatted(nutrient.name, true).length < colMaxChars">
                            {{ $filters.strLimit(recipeModel.nutrientNameFormatted(nutrient.name, true), colMaxChars) }}
                          </Popper>
                        </th>
                        <td class="px-2 py-1">
                          {{ petNutrientDisplayAmount(nutrient.name) }}
                        </td>
                        <td class="px-2 py-1">
                          {{ recipeNutrientDisplayAmount(nutrient.name) }}
                        </td>
                        <td class="px-2 py-1" :class="[rangeClassByNutrientName(nutrient.name)]">
                          {{ rangeLabelByNutrientName(nutrient.name) }}
                        </td>
                    </tr>
                    <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                      <th scope="row" class="md:hidden px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                        <Popper :content="recipeModel.nutrientNameFormatted('Omega 6:3 ratio')" :disabled="recipeModel.nutrientNameFormatted('Omega 6:3 ratio').length < colMaxChars">
                          {{ $filters.strLimit(recipeModel.nutrientNameFormatted('Omega 6:3 ratio'), colMaxChars) }}
                        </Popper>
                      </th>
                      <th scope="row" class="hidden md:table-cell px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                        <Popper :content="recipeModel.nutrientNameFormatted('Omega 6:3 ratio')" :disabled="recipeModel.nutrientNameFormatted('Omega 6:3 ratio').length < colMaxChars">
                          {{ $filters.strLimit(recipeModel.nutrientNameFormatted('Omega 6:3 ratio'), colMaxChars) }}
                        </Popper>
                      </th>
                      <td class="px-2 py-1"></td>
                      <td class="px-2 py-1">
                        {{ recipeModel.omega63RatioFormatted() }}
                      </td>
                      <td class="px-2 py-1"></td>
                    </tr>
                    <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">AMINO ACIDS</th>
                      <td class="px-2 py-1">&nbsp;</td>
                      <td class="px-2 py-1">&nbsp;</td>
                      <td class="px-2 py-1">&nbsp;</td>
                    </tr>
                    <tr v-for="(nutrient, index) in petNutrientsAminoAcids" :key="petNutrientAminoAcidKey(nutrient)"
                      :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                        <th scope="row" class="md:hidden px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                          <Popper :content="recipeModel.nutrientNameFormatted(nutrient.name, true)" :disabled="recipeModel.nutrientNameFormatted(nutrient.name, true).length < colMaxChars">
                            {{ $filters.strLimit(recipeModel.nutrientNameFormatted(nutrient.name, true), colMaxChars) }}
                          </Popper>
                        </th>
                        <th scope="row" class="hidden md:table-cell px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                          <Popper :content="recipeModel.nutrientNameFormatted(nutrient.name, true)" :disabled="recipeModel.nutrientNameFormatted(nutrient.name, true).length < colMaxChars">
                            {{ $filters.strLimit(recipeModel.nutrientNameFormatted(nutrient.name, true), colMaxChars) }}
                          </Popper>
                        </th>
                        <td class="px-2 py-1">
                          {{ petNutrientDisplayAmount(nutrient.name) }}
                        </td>
                        <td class="px-2 py-1">
                          {{ recipeNutrientDisplayAmount(nutrient.name) }}
                        </td>
                        <td class="px-2 py-1" :class="[rangeClassByNutrientName(nutrient.name)]">
                          {{ rangeLabelByNutrientName(nutrient.name) }}
                        </td>
                    </tr>
                    <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                      <th class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">OTHER</th>
                      <td class="px-2 py-1">&nbsp;</td>
                      <td class="px-2 py-1">&nbsp;</td>
                      <td class="px-2 py-1">&nbsp;</td>
                    </tr>
                    <tr v-for="(nutrient, index) in petNutrientsOther" :key="petNutrientOtherKey(nutrient)"
                      :class="index % 2 == 0 ? 'bg-white border-b dark:bg-gray-900 dark:border-gray-700' : 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700'">
                        <th scope="row" class="md:hidden px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                          <Popper :content="recipeModel.nutrientNameFormatted(nutrient.name, true)" :disabled="recipeModel.nutrientNameFormatted(nutrient.name, true).length < colMaxChars">
                            {{ $filters.strLimit(recipeModel.nutrientNameFormatted(nutrient.name, true), colMaxChars) }}
                          </Popper>
                        </th>
                        <th scope="row" class="hidden md:table-cell px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                          <Popper :content="recipeModel.nutrientNameFormatted(nutrient.name, true)" :disabled="recipeModel.nutrientNameFormatted(nutrient.name, true).length < colMaxChars">
                            {{ $filters.strLimit(recipeModel.nutrientNameFormatted(nutrient.name, true), colMaxChars) }}
                          </Popper>
                        </th>
                        <td class="px-2 py-1">
                          {{ petNutrientDisplayAmount(nutrient.name) }}
                        </td>
                        <td class="px-2 py-1">
                          {{ recipeNutrientDisplayAmount(nutrient.name) }}
                        </td>
                        <td class="px-2 py-1" :class="[rangeClassByNutrientName(nutrient.name)]">
                          {{ rangeLabelByNutrientName(nutrient.name) }}
                        </td>
                    </tr>
                    <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <td class="px-2 py-1 text-sm text-gray-900 dark:text-white break-words whitespace-normal" colspan="4">
                        <div style="max-width: 400px;">
                          <p>* An official high limit for dogs does not exist for this nutrient. A "high" level for this nutrient just means that it is higher than normal.</p>
                          <p class="mt-2">** An official low OR high limit for dogs does not exist for this nutrient. A "low" or a "high" level for this nutrient just means that it is lower or higher than normal.</p>
                          <p class="mt-2">*** Totals for this nutrient are under construction and should be independently verified. Consulting with a certified veterinary nutritionist is always recommended when customizing a recipe.</p>
                          <p class="mt-2">****Regardless of the water content of this recipe, ALWAYS make sure your pets have access to clean drinking water 24/7/365</p>
                        </div>
                      </td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div>

        <!-- Column 3 -->
        <div v-if="recipe.ingredients.length > 0" class="flex-shrink-0 w-[1700px] border-r-4 border-y-4 border-gray-500">
          <div class="text-gray-900 text-2xl text-left p-2">
            <div class="mx-2">
              Recipe Balance Analyzer
              <select class="
                      appearance-none
                      bg-white
                      text-gray-700
                      border
                      border-gray-400
                      rounded
                      py-1
                      px-2
                      mb-2
                      leading-tight
                      focus:outline-none
                      focus:bg-white
                      focus:border-gray-500
                      align-middle
                      "
                      v-model="petLifePhase"
              >
                <option v-for="phase in petLifPhases" :key="phase" :selected="petLifePhase == phase" :value="phase">{{ phase }}</option>
              </select>
              <label class="relative inline-flex items-center cursor-pointer ml-2 mr-8">
                <input type="checkbox" value="true" class="sr-only peer" v-model="adjustForEnergyDmbOver4kValue">
                <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-innogreen dark:peer-focus:ring-innogreen dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-innogreen"></div>
                <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Adjust for energy density >4000 kcal/kg DM</span>
              </label>
            </div>
          </div>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg max-h-[900px]">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead class="sticky top-0 text-xs text-white uppercase bg-innogreen">
                      <tr>
                        <th scope="col" class="px-2 py-1"></th>  
                        <th scope="col" class="px-2 py-1">
                          GRAND TOTALS:
                        </th>
                        <th scope="col" class="px-2 py-1">
                          AS FED:
                        </th>
                        <th scope="col" class="px-2 py-1">
                          DRY MATTER BASIS:
                        </th>
                        <th scope="col" class="px-2 py-1">
                          PER 1000 KCALS:
                        </th>
                        <th scope="col" class="px-2 py-1">
                          INGREDIENTS:
                        </th>
                        <th scope="col" class="px-2 py-1" v-for="i in (recipe.ingredients.length - 1)" :key="cellKey('nyb-main-header', i)"></th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr class="sticky top-[40px] bg-gray-100 border-b dark:bg-gray-900 dark:border-gray-700" style="z-index: 1;">
                      <th scope="col" class="px-2 py-1 text-center">
                          Nutrient / Data Point
                      </th>
                      <th scope="col" class="px-2 py-1">
                          <Popper :content="recipe.name" :disabled="recipe.name.length < headersMaxChars">
                            Recipe
                          </Popper>
                      </th>
                      <th scope="col" class="px-2 py-1"></th>
                      <th scope="col" class="px-2 py-1"></th>
                      <th scope="col" class="px-2 py-1"></th>
                      <th scope="col" class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="ingredientHeaderKey(ingredient)">
                          <Popper :content="ingredient.name" :disabled="ingredient.name.length < headersMaxChars">
                            {{ $filters.strLimit(ingredient.name, headersMaxChars) }}
                          </Popper>
                      </th>
                    </tr>
                    <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th scope="row" class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                        Total Grams
                      </th>
                      <td scope="row" class="px-2 py-1">
                        {{ batchIngredientsTotalGrams }}
                      </td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="ingredientTotalKey(ingredient)">
                        {{ ingredient.amount.total_g }}
                      </td>
                    </tr>  
                    <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                      <th scope="row" class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">% of recipe</th>
                      <td scope="row" class="px-2 py-1">100%</td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="ingredientPercentKey(ingredient)">
                        {{ ingredient.amount.percent_of_recipe }}%
                      </td>
                    </tr>
                    <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th scope="row" class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                        Total Dry Matter
                      </th>
                      <td scope="row" class="px-2 py-1">
                        {{ recipeModel.totalGramsDryMatterRounded }}g
                      </td>
                      <td scope="row" class="px-2 py-1">
                        {{ batchTotalDryMatterPercentAsFedRounded }}%
                      </td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td scope="row" class="px-2 py-1">
                        {{ recipeModel.dryMatterPer1000KcalFormatted() }}
                      </td>
                      <td class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="ingredientGramsDryMatterKey(ingredient)">
                        {{ recipeModel.ingredientGramsDryMatterFormatted(ingredient)}}
                      </td>
                    </tr>
                    <tr v-for="(nutrient, index) in recipeModel.nutrientsByCategoryName('Macros')" :key="recipeNutrientKey(recipe, nutrient)"
                      :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                        <th scope="row" class="md:hidden px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                          <Popper :content="recipeModel.nutrientNameFormatted(nutrient.name)" :disabled="recipeModel.nutrientNameFormatted(nutrient.name).length < nutrientMaxChars">
                            {{ $filters.strLimit(recipeModel.nutrientNameFormatted(nutrient.name), nutrientMaxChars) }}
                          </Popper>
                        </th>
                        <th scope="row" class="hidden md:table-cell px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                          {{ recipeModel.nutrientNameFormatted(nutrient.name) }}
                        </th>
                        <td class="px-2 py-1">
                          {{ recipeModel.nutrientTotalFormatted(nutrient.name, nutrient) }}
                        </td>
                        <td class="px-2 py-1">{{ recipeModel.nutrientAsFedFormatted(nutrient.name) }}</td>
                        <td class="px-2 py-1" :class="[dmbRangeClassByNutrientName(nutrient.name)]">{{ recipeModel.nutrientDryMatterBasisFormatted(nutrient.name) }}</td>
                        <td class="px-2 py-1" :class="[dmRangeClassByNutrientName(nutrient.name)]">{{ recipeModel.nutrientDryMatterPer1000KcalFormatted(nutrient.name) }}</td>
                        <td class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="ingredientBodyKey(ingredient)">
                          {{ recipeModel.ingredientNutrientAmountFormatted(ingredient, nutrient.name) }}
                        </td>
                    </tr>
                    <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                      <th scope="row" class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                        kcal from protein
                      </th>
                      <td scope="row" class="px-2 py-1">
                        {{ recipeModel.kcalFromProteinRounded }}kcal
                      </td>
                      <td scope="row" class="px-2 py-1">
                        {{ recipeModel.kcalFromProteinPercentAsFedRounded }}%
                      </td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="cellKey('batch-kcal-from-protein', ingredient.id)"></td>
                    </tr>
                    <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th scope="row" class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                        kcal from fat
                      </th>
                      <td scope="row" class="px-2 py-1">
                        {{ recipeModel.kcalFromFatRounded }}kcal
                      </td>
                      <td scope="row" class="px-2 py-1">
                        {{ recipeModel.kcalFromFatPercentAsFedRounded }}%
                      </td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="cellKey('batch-kcal-from-fat', ingredient.id)"></td>
                    </tr>
                    <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                      <th scope="row" class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                        kcal from carbs
                      </th>
                      <td scope="row" class="px-2 py-1">
                        {{ recipeModel.kcalFromCarbsRounded }}kcal
                      </td>
                      <td scope="row" class="px-2 py-1">
                        {{ recipeModel.kcalFromCarbsPercentAsFedRounded }}%
                      </td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="cellKey('batch-kcal-from-carbs', ingredient.id)"></td>
                    </tr>
                    <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th scope="row" class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                        keto ratio [fat/(protein +carbs)]
                      </th>
                      <td scope="row" class="px-2 py-1">
                        {{ recipeModel.ketoRatioFormatted() }}
                      </td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="cellKey('batch-keto-ratio', ingredient.id)"></td>
                    </tr>
                    <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                      <th scope="row" class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        MINERALS:
                      </th>
                      <td scope="row" class="px-2 py-1"></td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="cellKey('minerals-header', ingredient.id)"></td>
                    </tr>
                    <tr v-for="(nutrientName, index) in batchMineralItems" :key="cellKey('batch-mineral-item', nutrientName)"
                      :class="index % 2 == 0 ? 'bg-white border-b dark:bg-gray-900 dark:border-gray-700' : 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700'">
                        <th scope="row" class="md:hidden px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                          <Popper :content="recipeModel.nutrientNameFormatted(nutrientName)" :disabled="recipeModel.nutrientNameFormatted(nutrientName).length < nutrientMaxChars">
                            {{ $filters.strLimit(recipeModel.nutrientNameFormatted(nutrientName), nutrientMaxChars) }}
                          </Popper>
                        </th>
                        <th scope="row" class="hidden md:table-cell px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                          {{ recipeModel.nutrientNameFormatted(nutrientName) }}
                        </th>
                        <td class="px-2 py-1">
                          {{ recipeModel.nutrientTotalFormatted(nutrientName) }}
                        </td>
                        <td class="px-2 py-1">{{ recipeModel.nutrientAsFedFormatted(nutrientName) }}</td>
                        <td class="px-2 py-1" :class="[dmbRangeClassByNutrientName(nutrientName)]">{{ recipeModel.nutrientDryMatterBasisFormatted(nutrientName) }}</td>
                        <td class="px-2 py-1" :class="[dmRangeClassByNutrientName(nutrientName)]">{{ recipeModel.nutrientDryMatterPer1000KcalFormatted(nutrientName) }}</td>
                        <td class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="cellKey('batch-mineral-item-ingredient', ingredient.id)">
                          {{ recipeModel.ingredientNutrientAmountFormatted(ingredient, nutrientName) }}
                        </td>
                    </tr>
                    <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                      <th scope="row" class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        VITAMINS:
                      </th>
                      <td scope="row" class="px-2 py-1"></td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="cellKey('vitamins-header', ingredient.id)"></td>
                    </tr>
                    <tr v-for="(nutrient, index) in recipeModel.nutrientsByCategoryName('Vitamins')" :key="cellKey('batch-vitamin-item', nutrient.name)"
                      :class="index % 2 == 0 ? 'bg-white border-b dark:bg-gray-900 dark:border-gray-700' : 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700'">
                        <th scope="row" class="md:hidden px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                          <Popper :content="recipeModel.nutrientNameFormatted(nutrient.name)" :disabled="recipeModel.nutrientNameFormatted(nutrient.name).length < nutrientMaxChars">
                            {{ $filters.strLimit(recipeModel.nutrientNameFormatted(nutrient.name), nutrientMaxChars) }}
                          </Popper>
                        </th>
                        <th scope="row" class="hidden md:table-cell px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                          {{ recipeModel.nutrientNameFormatted(nutrient.name) }}
                        </th>
                        <td class="px-2 py-1">
                          {{ recipeModel.nutrientTotalFormatted(nutrient.name) }}
                        </td>
                        <td class="px-2 py-1">{{ recipeModel.nutrientAsFedFormatted(nutrient.name) }}</td>
                        <td class="px-2 py-1" :class="[dmbRangeClassByNutrientName(nutrient.name)]">{{ recipeModel.nutrientDryMatterBasisFormatted(nutrient.name) }}</td>
                        <td class="px-2 py-1" :class="[dmRangeClassByNutrientName(nutrient.name)]">{{ recipeModel.nutrientDryMatterPer1000KcalFormatted(nutrient.name) }}</td>
                        <td class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="cellKey('batch-vitamin-item-ingredient', ingredient.id)">
                          {{ recipeModel.ingredientNutrientAmountFormatted(ingredient, nutrient.name) }}
                        </td>
                    </tr>
                    <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th scope="row" class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        FATS:
                      </th>
                      <td scope="row" class="px-2 py-1"></td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="cellKey('fats-header', ingredient.id)"></td>
                    </tr>
                    <tr v-for="(nutrient, index) in recipeModel.nutrientsByCategoryName('Fats')" :key="cellKey('batch-fat-item', nutrient.name)"
                      :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                        <th scope="row" class="md:hidden px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                          <Popper :content="recipeModel.nutrientNameFormatted(nutrient.name)" :disabled="recipeModel.nutrientNameFormatted(nutrient.name).length < nutrientMaxChars">
                            {{ $filters.strLimit(recipeModel.nutrientNameFormatted(nutrient.name), nutrientMaxChars) }}
                          </Popper>
                        </th>
                        <th scope="row" class="hidden md:table-cell px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                          {{ recipeModel.nutrientNameFormatted(nutrient.name) }}
                        </th>
                        <td class="px-2 py-1">
                          {{ recipeModel.nutrientTotalFormatted(nutrient.name) }}
                        </td>
                        <td class="px-2 py-1">{{ recipeModel.nutrientAsFedFormatted(nutrient.name) }}</td>
                        <td class="px-2 py-1" :class="[dmbRangeClassByNutrientName(nutrient.name)]">{{ recipeModel.nutrientDryMatterBasisFormatted(nutrient.name) }}</td>
                        <td class="px-2 py-1" :class="[dmRangeClassByNutrientName(nutrient.name)]">{{ recipeModel.nutrientDryMatterPer1000KcalFormatted(nutrient.name) }}</td>
                        <td class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="cellKey('batch-fat-item-ingredient', ingredient.id)">
                          {{ recipeModel.ingredientNutrientAmountFormatted(ingredient, nutrient.name) }}
                        </td>
                    </tr>
                    <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                      <th scope="row" class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                        Omega 6:3 ratio
                      </th>
                      <td scope="row" class="px-2 py-1">
                        {{ recipeModel.omega63RatioFormatted() }}
                      </td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="cellKey('batch-omega-ratio', ingredient.id)"></td>
                    </tr>
                    <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th scope="row" class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        AMINO ACIDS:
                      </th>
                      <td scope="row" class="px-2 py-1"></td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="cellKey('amino-acids-header', ingredient.id)"></td>
                    </tr>
                    <tr v-for="(nutrient, index) in recipeModel.nutrientsByCategoryName('Amino Acids')" :key="cellKey('batch-amino-acid-item', nutrient.name)"
                      :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                        <th scope="row" class="md:hidden px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                          <Popper :content="recipeModel.nutrientNameFormatted(nutrient.name)" :disabled="recipeModel.nutrientNameFormatted(nutrient.name).length < nutrientMaxChars">
                            {{ $filters.strLimit(recipeModel.nutrientNameFormatted(nutrient.name), nutrientMaxChars) }}
                          </Popper>
                        </th>
                        <th scope="row" class="hidden md:table-cell px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                          {{ recipeModel.nutrientNameFormatted(nutrient.name) }}
                        </th>
                        <td class="px-2 py-1">
                          {{ recipeModel.nutrientTotalFormatted(nutrient.name) }}
                        </td>
                        <td class="px-2 py-1">{{ recipeModel.nutrientAsFedFormatted(nutrient.name) }}</td>
                        <td class="px-2 py-1" :class="[dmbRangeClassByNutrientName(nutrient.name)]">{{ recipeModel.nutrientDryMatterBasisFormatted(nutrient.name) }}</td>
                        <td class="px-2 py-1" :class="[dmRangeClassByNutrientName(nutrient.name)]">{{ recipeModel.nutrientDryMatterPer1000KcalFormatted(nutrient.name) }}</td>
                        <td class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="cellKey('batch-amino-acid-item-ingredient', ingredient.id)">
                          {{ recipeModel.ingredientNutrientAmountFormatted(ingredient, nutrient.name) }}
                        </td>
                    </tr>
                    <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                      <th scope="row" class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        OTHER:
                      </th>
                      <td scope="row" class="px-2 py-1"></td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td scope="row" class="px-2 py-1"></td>
                      <td class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="cellKey('other-header', ingredient.id)"></td>
                    </tr>
                    <tr v-for="(nutrient, index) in recipeModel.nutrientsByCategoryName(null)" :key="cellKey('batch-other-item', nutrient.name)"
                      :class="index % 2 == 0 ? 'bg-white border-b dark:bg-gray-900 dark:border-gray-700' : 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700'">
                        <th scope="row" class="md:hidden px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                          <Popper :content="recipeModel.nutrientNameFormatted(nutrient.name)" :disabled="recipeModel.nutrientNameFormatted(nutrient.name).length < nutrientMaxChars">
                            {{ $filters.strLimit(recipeModel.nutrientNameFormatted(nutrient.name), nutrientMaxChars) }}
                          </Popper>
                        </th>
                        <th scope="row" class="hidden md:table-cell px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                          {{ recipeModel.nutrientNameFormatted(nutrient.name) }}
                        </th>
                        <td class="px-2 py-1">
                          {{ recipeModel.nutrientTotalFormatted(nutrient.name) }}
                        </td>
                        <td class="px-2 py-1">{{ recipeModel.nutrientAsFedFormatted(nutrient.name) }}</td>
                        <td class="px-2 py-1" :class="[dmbRangeClassByNutrientName(nutrient.name)]">{{ recipeModel.nutrientDryMatterBasisFormatted(nutrient.name) }}</td>
                        <td class="px-2 py-1" :class="[dmRangeClassByNutrientName(nutrient.name)]">{{ recipeModel.nutrientDryMatterPer1000KcalFormatted(nutrient.name) }}</td>
                        <td class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="cellKey('batch-other-item-ingredient', ingredient.id)">
                          {{ recipeModel.ingredientNutrientAmountFormatted(ingredient, nutrient.name) }}
                        </td>
                    </tr>
                    <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                      <td class="px-2 py-1 text-sm text-gray-900 dark:text-white break-words" :colspan="recipe.ingredients.length + 5">
                        *** totals for this nutrient are under construction and should be independently verified. Consulting with a certified veterinary nutritionist is always recommended when customizing a recipe.
                      </td>
                    </tr>
                  </tbody>
              </table>
          </div>
        </div>
        
    </div>


  </div>
</template>

<script setup>
  import { onMounted, ref, computed } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import _ from 'lodash';
  import PetService from '../../services/pet.service.js'
  import RecipeService from '../../services/recipe.service.js'
  import CaPRatioService from '../../services/caPRatio.service.js'
  import Popper from "vue3-popper"
  import IngredientService from '../../services/ingredient.service'
  import Swal from 'sweetalert2'
  import { useToast } from "vue-toastification"
  import { useRecipeModel } from '@/composables/useRecipeModel'

  const route = useRoute()
  const router = useRouter()
  const toast = useToast()

  let pet = ref({name: '', daily_nutrition_breakdown: null})
  let petGramsPerDay = ref('')
  let recipe = ref({ nutrition_breakdown: { total_qty: 2980 }, ingredients: []})
  let petNutrients = ref([])
  let petProfileAnalyzerMacroItems = ref([])
  let petProfileAnalyzerMineralItems = ref([])
  let batchMineralItems = ref([])
  let recipeNutrients = ref([])
  let targetDeviations = ref([])
  let nutrientRanges = ref([]) // 1 for low, 2 for optimal, 3 for high
  let caPRatio = ref({low_max: 50, high_min: 150})
  let recipeCaAmount = ref(0)
  let recipePAmount = ref(0)
  let nutrientMaxChars = ref(12)
  let headersMaxChars = ref(20)
  let colMaxChars = ref(20)
  let ingredients = ref([])
  let searching = ref(false)
  let saving = ref(false)
  let errors = ref([])
  let query = ref(null)
  let pets = ref([])
  let txtMaxBatch = ref('MAXIMUM batch size for model#CP3A is 2898 grams')
  let selPetId = ref(route.params.petId)
  let petLifePhase = ref('Adult Dog')
  let petLifPhases = ref(['Adult Dog', 'Puppy'])
  let adjustForEnergyDmbOver4kValue = ref(false)
  let optimizeForEnergy = ref(false)

  const recipeModel = useRecipeModel(computed(() => recipe.value))

  onMounted( () => {
    loadAll()
  })

  const adjustForEnergyDmbOver4k = computed({
    get: () => adjustForEnergyDmbOver4kValue.value,
    set: (value) => value === "true" ? adjustForEnergyDmbOver4kValue.value = true : adjustForEnergyDmbOver4kValue.value = false
  })

  const ingredientGramsDontMatchRecipe = computed({
    get: () => batchIngredientsTotalGrams.value != recipe.value.nutrition_breakdown.total_qty
  })

  const totalDisplay = computed({
      get: () => getTotalGramsText()
  })

  const servingMultiplier = computed(() => {
    if (optimizeForEnergy.value) {
      let petEnergy = petNutrients.value.find(nutrient => nutrient.name == 'Energy')
      let recipeEnergy = recipeNutrients.value.find(nutrient => nutrient.name == 'Energy')
      if (petEnergy == undefined || recipeEnergy == undefined) return 1
      return petEnergy.amount.qty / recipeEnergy.amount.qty
    }
    return 1
  })

  const petGramsPerDayRounded = computed({
    get: () => Math.round(petGramsPerDay.value * servingMultiplier.value)
  })

  const petCupsPerDayRounded = computed({
      get: () => Math.round((petGramsPerDay.value * servingMultiplier.value) / 226)
  })

  const petCupsPerDayText = computed({
      get: () => petCupsPerDayRounded.value == 1 ? petCupsPerDayRounded.value + ' cup' : petCupsPerDayRounded.value + ' cups'
  })

  const recipeTotalDryMatterRounded = computed({
    get: () => Math.round((petGramsPerDay.value * servingMultiplier.value) - (recipeNutrients.value.find(nutrient => nutrient.name == 'Water').amount.qty * servingMultiplier.value))
  })

  const petNutrientsMacros = computed({
    get: () => petNutrients.value.filter(nutrient => nutrient.category_name === 'Macros'),
  })

  const petNutrientsMinerals = computed({
    get: () => petNutrients.value.filter(nutrient => nutrient.category_name === 'Minerals'),
  })

  const petNutrientsVitamins = computed({
    get: () => petNutrients.value.filter(nutrient => nutrient.category_name === 'Vitamins'),
  })

  const petNutrientsFats = computed({
    get: () => petNutrients.value.filter(nutrient => nutrient.category_name === 'Fats'),
  })

  const petNutrientsAminoAcids = computed({
    get: () => petNutrients.value.filter(nutrient => nutrient.category_name === 'Amino Acids'),
  })

  const petNutrientsOther = computed({
    get: () => petNutrients.value.filter(nutrient => nutrient.category_name == null),
  })

  const recipeKcalFromProtein = computed({
    get: () => recipeNutrients.value.find(nutrient => nutrient.name == 'crude Protein').amount.qty * 3.5
  })

  const recipeKcalFromFat = computed({
    get: () => recipeNutrients.value.find(nutrient => nutrient.name == 'Total lipid (fat)').amount.qty * 8.5
  })

  const recipeKcalFromCarbs = computed({
    get: () => recipeNutrients.value.find(nutrient => nutrient.name == 'Carbohydrate, by difference').amount.qty * 3.5
  })

  const recipeKcalFromProteinPercentAsFedRounded = computed({
    get: () => Math.round((recipeKcalFromProtein.value / (recipeKcalFromProtein.value + recipeKcalFromFat.value + recipeKcalFromCarbs.value)) * 100)
  })

  const recipeKcalFromFatPercentAsFedRounded = computed({
    get: () => Math.round((recipeKcalFromFat.value / (recipeKcalFromProtein.value + recipeKcalFromFat.value + recipeKcalFromCarbs.value)) * 100)
  })

  const recipeKcalFromCarbsPercentAsFedRounded = computed({
    get: () => Math.round((recipeKcalFromCarbs.value / (recipeKcalFromProtein.value + recipeKcalFromFat.value + recipeKcalFromCarbs.value)) * 100)
  })

  const recipeCaVitaminCRatioPercent = computed({
    get: () => (recipeNutrients.value.find(nutrient => nutrient.name == 'Calcium, Ca').amount.qty / recipeNutrients.value.find(nutrient => nutrient.name == 'Vitamin C, total ascorbic acid').amount.qty) * 100
  })

  const recipeZnCuRatioPercent = computed({
    get: () => (recipeNutrients.value.find(nutrient => nutrient.name == 'Zinc, Zn').amount.qty / recipeNutrients.value.find(nutrient => nutrient.name == 'Copper, Cu').amount.qty) * 100
  })

  const batchIngredientsTotalGrams = computed(() => {
    return recipe.value.ingredients.reduce((sum, ingredient) => {
      return sum + parseFloat(ingredient.amount.total_g);
    }, 0);
  });

  const batchTotalDryMatterPercentAsFedRounded = computed({
    get: () => roundTenth((recipeModel.totalGramsDryMatter.value / batchIngredientsTotalGrams.value) * 100)
  })

  const energyDryMatterBasis = computed({
    get: () => recipeModel.nutrientDryMatterBasis('Energy')
  })

  const dryMatterBasisMultiplier = computed(() => {
    if (energyDryMatterBasis.value <= 4000) return 1
    return energyDryMatterBasis.value / 4000
  })

  const servingSizeExceeds925PercentOfBodyWeight = computed(() => {
    let percentOfBodyWeight = (petGramsPerDayRounded.value / (pet.value.weight_kg * 1000)) * 100
    return percentOfBodyWeight > 9.25
  })

  const loadAll = async () => {
    saving.value = true
    Promise.all([PetService.getPetWithDailyNutritionAndDietaryPerKgNeed(selPetId.value), RecipeService.getRecipeWithNutritionAndHiddenNutrients(route.params.recipeId), getCaPRatio(), getPets()]).then((values) => {
      pet.value = values[0].data.pet
      recipe.value = values[1].data.recipe
      console.log('protein',recipeModel.nutrientByName('crude Protein').id)
      console.log('total grams dry matter', recipeModel.totalGramsDryMatter.value)
      console.log('keto ratio', recipeModel.ketoRatioFormatted())
      console.log('ca/p ratio', recipeModel.caPRatio.value)
      resetData()
    });
  }

  const getPets = async () => {
    try {
      let res = await PetService.listPets()
      pets.value = res.data.pets
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const resetData = () => {
    petNutrients.value = []
    recipeNutrients.value = []
    targetDeviations.value = []
    nutrientRanges.value = []
    petGramsPerDay.value = pet.value.daily_nutrition_breakdown.total_qty
    const recipe_multiplier = petGramsPerDay.value / recipe.value.nutrition_breakdown.total_qty
    console.log('recipe_multiplier', recipe_multiplier)
    for (let i = 0; i < pet.value.daily_nutrition_breakdown.nutrients.length; i++) {
      let nutrient = pet.value.daily_nutrition_breakdown.nutrients[i]
      petNutrients.value.push(nutrient)
      if(!_.find(recipe.value.nutrition_breakdown.nutrients, {id: nutrient.id})) {
        let recipe_nutrient = structuredClone(nutrient)
        recipe_nutrient.amount.qty = 0
        recipeNutrients.value.push(recipe_nutrient)
      }
    }
    for (let i = 0; i < recipe.value.nutrition_breakdown.nutrients.length; i++) {
      let nutrient = structuredClone(recipe.value.nutrition_breakdown.nutrients[i])
      if (nutrient.amount.qty > 0) nutrient.amount.qty = Math.round(nutrient.amount.qty * recipe_multiplier * 1000) / 1000

      // maybe in the future we'll change how this works
      if (nutrient.name == 'Calcium, Ca') recipeCaAmount.value = nutrient.amount.qty
      else if (nutrient.name == 'Phosphorus, P') recipePAmount.value = nutrient.amount.qty

      recipeNutrients.value.push(nutrient)
      if(!_.find(pet.value.daily_nutrition_breakdown.nutrients, {id: nutrient.id})) {
        let pet_nutrient = structuredClone(nutrient)
        pet_nutrient.amount.qty = 0
        petNutrients.value.push(pet_nutrient)
      }
    }
    petNutrients.value = _.sortBy(petNutrients.value, 'default_sort_order')
    recipeNutrients.value = _.sortBy(recipeNutrients.value, 'default_sort_order')
    for (let i = 0; i < petNutrients.value.length; i++) {
      let petNutrient = petNutrients.value[i]
      if (petNutrient.amount.qty == 0) {
        let deviation = 'N/A'
        targetDeviations.value.push(deviation)
        nutrientRanges.value.push(2)
      } else {
        let recipeNutrient = recipeNutrients.value[i]
        let deviation = Math.round(((recipeNutrient.amount.qty * servingMultiplier.value) / petNutrient.amount.qty) * 100)
        let deviationStr = deviation + '%'

        let dpknNutrient = _.find(pet.value.dietary_per_kg_need.nutrition_breakdown.nutrients, function(n) {
          return n.id == recipeNutrient.id
        })

        if (dpknNutrient === undefined) nutrientRanges.value.push(2)
        else if (deviation <= dpknNutrient.amount.low_max) nutrientRanges.value.push(1)
        else if (deviation >= dpknNutrient.amount.high_min) nutrientRanges.value.push(3)
        else nutrientRanges.value.push(2)

        targetDeviations.value.push(deviationStr)
      }
    }

    resetPetProfileAnalyzerMacroItems()
    resetPetProfileAnalyzerMineralItems()
    resetBatchMineralItems()

    saving.value = false
  }

  const resetPetProfileAnalyzerMacroItems = () => {
    let petNutrientsMacrosNames = petNutrientsMacros.value.map(nutrient => nutrient.name)
    petProfileAnalyzerMacroItems.value = [
      'Total Dry Matter',
      ...petNutrientsMacrosNames,
      'kcal from protein',
      'kcal from fat',
      'kcal from carbs',
      'keto ratio [fat/(protein +carbs)]'
    ]
  }

  const resetPetProfileAnalyzerMineralItems = () => {
    let petNutrientsMineralsNames = petNutrientsMinerals.value.map(nutrient => nutrient.name)
    petProfileAnalyzerMineralItems.value = []
    for (let i = 0; i < petNutrientsMineralsNames.length; i++) {
      let name = petNutrientsMineralsNames[i]
      petProfileAnalyzerMineralItems.value.push(name)
      if (name == 'Calcium, Ca') {
        petProfileAnalyzerMineralItems.value.push('Ca/P Ratio')
        petProfileAnalyzerMineralItems.value.push('Ca/Vitamin C Ratio')
      } else if (name == 'Zinc, Zn') {
        petProfileAnalyzerMineralItems.value.push('Zn:Cu ratio')
      }
    }
  }

  const resetBatchMineralItems = () => {
    let nutrientNames = recipeModel.nutrientsByCategoryName('Minerals').map(nutrient => nutrient.name)
    batchMineralItems.value = []
    for (let i = 0; i < nutrientNames.length; i++) {
      let name = nutrientNames[i]
      batchMineralItems.value.push(name)
      if (name == 'Calcium, Ca') {
        batchMineralItems.value.push('Ca/P Ratio')
        batchMineralItems.value.push('Ca/Vitamin C Ratio')
      } else if (name == 'Zinc, Zn') {
        batchMineralItems.value.push('Zn:Cu ratio')
      }
    }
  }

  const roundTenth = (value) => {
    return Math.round(value * 10) / 10 // Rounds to the nearest tenth
  }

  const roundHundredth = (value) => {
    return Math.round(value * 100) / 100 // Rounds to the nearest hundredth
  }

  const roundToDecimals = (value, decimals) => {
    return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
  }

  const petNutrientDisplayAmount = (nutrientName) => {
    switch (nutrientName) {
      case 'Ca/P Ratio': {
        return '1:1 to 2:1'
      }
      case 'Total Dry Matter':
      case 'kcal from protein':
      case 'kcal from fat':
      case 'kcal from carbs':
      case 'keto ratio [fat/(protein +carbs)]':
      case 'Ca/Vitamin C Ratio':
      case 'Zn:Cu ratio': {
        return ''
      }
      case 'EPA + DHA undifferentiated':
      case 'Omega 3a-linolenic acid (ALA, 18:3)':
      case 'Omega 6 Linoleic Acid (LA, 18:2)':
      case 'Omega 6 Arachidonic Acid (AA, 20:4)': {
        let nutrient = petNutrients.value.find(nutrient => nutrient.name == nutrientName)
        return roundHundredth(nutrient.amount.qty).toString() + nutrient.amount.unit_type
      }
      default: {
        let nutrient = petNutrients.value.find(nutrient => nutrient.name == nutrientName)
        return Math.round(nutrient.amount.qty).toString() + nutrient.amount.unit_type
      }
    }
  }

  const recipeNutrientDisplayAmount = (nutrientName) => {
    switch (nutrientName) {
      case 'Total Dry Matter': {
        return recipeTotalDryMatterRounded.value.toString() + 'g'
      }
      case 'kcal from protein': {
        return recipeKcalFromProteinPercentAsFedRounded.value.toString() + '%'
      }
      case 'kcal from fat': {
        return recipeKcalFromFatPercentAsFedRounded.value.toString() + '%'
      }
      case 'kcal from carbs': {
        return recipeKcalFromCarbsPercentAsFedRounded.value.toString() + '%'
      }
      case 'keto ratio [fat/(protein +carbs)]': {
        return recipeModel.ketoRatioFormatted()
      }
      case 'Ca/P Ratio': {
        return recipeModel.caPRatioFormatted()
      }
      case 'Ca/Vitamin C Ratio': {
        return (recipeCaVitaminCRatioPercent.value / 100).toFixed(1) + ':1'
      }
      case 'Zn:Cu ratio': {
        return (recipeZnCuRatioPercent.value / 100).toFixed(1) + ':1'
      }
      case 'EPA + DHA undifferentiated':
      case 'Omega 3a-linolenic acid (ALA, 18:3)':
      case 'Omega 6 Linoleic Acid (LA, 18:2)':
      case 'Omega 6 Arachidonic Acid (AA, 20:4)': {
        let nutrient = recipeNutrients.value.find(nutrient => nutrient.name == nutrientName)
        return roundHundredth(nutrient.amount.qty * servingMultiplier.value).toString() + nutrient.amount.unit_type
      }
      default: {
        let nutrient = recipeNutrients.value.find(nutrient => nutrient.name == nutrientName)
        return Math.round(nutrient.amount.qty * servingMultiplier.value).toString() + nutrient.amount.unit_type
      }
    }
  }

  const getTotalGramsText = () => {
    let deviation = recipeIngredientsGramsDeviation()
    if (deviation > 0) return batchIngredientsTotalGrams.value + " (" + deviation + " under " + recipe.value.nutrition_breakdown.total_qty + ")"
    else if (deviation < 0) return batchIngredientsTotalGrams.value + " (" + (deviation * -1) + " over " + recipe.value.nutrition_breakdown.total_qty + ")"
    else return recipe.value.nutrition_breakdown.total_qty
  }

  const recipeIngredientsGramsDeviation = () => {
    return recipe.value.nutrition_breakdown.total_qty - batchIngredientsTotalGrams.value
  }

  const searchIngredients = async () => {
    searching.value = true
    try {
      let res = await IngredientService.searchIngredientsByName(query.value)
      ingredients.value = res.data.ingredients
      searching.value = false
    } catch (err) {
      errors.value = err.response.data.errors
      searching.value = false
    }
  }

  const showPossiblyUnsafe = (ingredient) => {
    let msg = "This ingredient is possibly unsafe or some dogs may be allergic.<br>"
    if (ingredient.notes) {
      for (let i = 0; i < ingredient.notes.length; i++) {
        msg += ingredient.notes[i].msg + "<br>"
      }
    }
    Swal.fire(ingredient.name, msg, 'warning')
  }

  const showIngredientNotes = (ingredient) => {
    if (ingredient.notes) {
      let msg = ''
      for (let i = 0; i < ingredient.notes.length; i++) {
        msg += ingredient.notes[i].msg + "<br>"
      }
      Swal.fire(ingredient.name, msg, 'info')
    }
  }

  const addIngredient = async (ingredient) => {
    let data = new FormData();
    data.append('ingredients', JSON.stringify([{id: ingredient.id, total_g: ingredient.total_g}]))

    try {
      saving.value = true
      let res = await RecipeService.addIngredients(recipe.value.id, data)
      // let res = await RecipeService.getRecipeWithNutrition(recipe.value.id)
      recipe.value = res.data.recipe
      toast.success(ingredient.name + " added.")
      resetData()
    } catch (err) {
      saving.value = false
      if (err.response.data.errors && Array.isArray(err.response.data.errors)) errors.value = err.response.data.errors
      toast.error(err.response.data.message)
    }
  }

  const removeIngredientAtIndex = async (index) => {
    recipe.value.ingredients.splice(index, 1)
    editRecipe()
  }

  const editRecipe = async () => {

    let data = new FormData();
    data.append('recipe', JSON.stringify(recipe.value))

    try {
      saving.value = true
      let res = await RecipeService.updateRecipeIngredients(recipe.value.id, data)
      // let res = await RecipeService.getRecipeWithNutrition(recipe.value.id)
      recipe.value = res.data.recipe
      toast.success(recipe.value.name + " saved.")
      resetData()
    } catch (err) {
      saving.value = false
      if (err.response.data.errors && Array.isArray(err.response.data.errors)) errors.value = err.response.data.errors
      toast.error(err.response.data.message)
    }
  }

  const selPetChanged = async () => {
    router.replace({ name: 'RecipeAdvancedEditForPet', params: { recipeId: recipe.value.id, petId: selPetId.value } })
    loadAll()
  }

  const getCaPRatio = async () => {
    try {
      let res = await CaPRatioService.getDefault()
      caPRatio.value = res.data.ca_p_ratio
      console.log('got ca/p ratio', caPRatio.value)
    } catch (err) {
      console.log('Error getting Ca/P Ratio.')
    }
  }

  const rangeLabelByNutrientName = (nutrientName) => {
    switch (nutrientName) {
      case 'Ca/P Ratio': {
        return caPRatioLabel()
      }
      case 'Total Dry Matter':
      case 'kcal from protein':
      case 'kcal from fat':
      case 'kcal from carbs':
      case 'keto ratio [fat/(protein +carbs)]':
      case 'Ca/Vitamin C Ratio':
      case 'Zn:Cu ratio': {
        return ''
      }
      default: {
        let index = recipeNutrients.value.findIndex(nutrient => nutrient.name == nutrientName)
        if (nutrientRanges.value[index] == 1) return 'Low'
        else if (nutrientRanges.value[index] == 2) return 'Optimal'
        else return 'High'
      }
    }
  }

  const rangeClassByNutrientName = (nutrientName) => {
    switch (nutrientName) {
      case 'Total Dry Matter':
      case 'kcal from protein':
      case 'kcal from fat':
      case 'kcal from carbs':
      case 'keto ratio [fat/(protein +carbs)]':
      case 'Ca/P Ratio': {
        return caPRatioClass()
      }
      case 'Ca/Vitamin C Ratio':
      case 'Zn:Cu ratio': {
        return ''
      }
      default: {
        let index = recipeNutrients.value.findIndex(nutrient => nutrient.name == nutrientName)
        if (nutrientRanges.value[index] == 1) return 'text-yellow-500'
        else if (nutrientRanges.value[index] == 2) return 'text-green-500'
        else return 'text-red-500'
      }
    }
  }

  const dmbRangeClassByNutrientName = (nutrientName) => {
    let nutrient = recipeNutrients.value.find(nutrient => nutrient.name == nutrientName)
    if (!nutrient) return ''

    let recipeDmb = null
    let dmbLowMax = null
    let dmbHighMin = null

    let multiplier = 1
    if (adjustForEnergyDmbOver4k.value) multiplier = dryMatterBasisMultiplier.value

    if (petLifePhase.value == 'Puppy') {
      dmbLowMax = nutrient.dmb_low_max_puppy === null ? null : roundHundredth(nutrient.dmb_low_max_puppy * multiplier)
      dmbHighMin = nutrient.dmb_high_min_puppy === null ? null : roundHundredth(nutrient.dmb_high_min_puppy * multiplier)
    } else {
      dmbLowMax = nutrient.dmb_low_max_adult_dog === null ? null : roundHundredth(nutrient.dmb_low_max_adult_dog * multiplier)
      dmbHighMin = nutrient.dmb_high_min_adult_dog === null ? null : roundHundredth(nutrient.dmb_high_min_adult_dog * multiplier)
    }
    if (dmbLowMax == null && dmbHighMin == null) return ''

    switch (nutrientName) {
      case 'Cystine': {
        recipeDmb = roundHundredth((recipeModel.nutrientDryMatterBasis(nutrientName) + recipeModel.nutrientDryMatterBasis('Methionine')) * 100)
        break
      }
      case 'Tyrosine': {
        recipeDmb = roundHundredth((recipeModel.nutrientDryMatterBasis(nutrientName) + recipeModel.nutrientDryMatterBasis('Phenylalanine')) * 100)
        break
      }
      case 'Energy':
      case 'Iron, Fe':
      case 'Zinc, Zn':
      case 'Copper, Cu':
      case 'Manganese, Mn':
      case 'Selenium, Se':
      case 'Iodine, I':
      case 'Thiamin':
      case 'Riboflavin':
      case 'Niacin':
      case 'Pantothenic acid':
      case 'Vitamin B-6':
      case 'Folate, total':
      case 'Choline, total':
      case 'Vitamin B-12':
      case 'vitamin A':
      case 'Vitamin E (alpha-tocopherol)':
      case 'Vitamin D3 (cholecalciferol)': {
        recipeDmb = roundHundredth(recipeModel.nutrientDryMatterBasis(nutrientName))
        break
      }
      default: {
        recipeDmb = roundHundredth(recipeModel.nutrientDryMatterBasis(nutrientName) * 100)
        break
      }
    }

    console.log('nutrientName', nutrientName, 'recipeDmb', recipeDmb, 'dmbLowMax', dmbLowMax, 'dmbHighMin', dmbHighMin)

    if (dmbLowMax != null && recipeDmb <= dmbLowMax) return 'text-yellow-500'
    else if (dmbHighMin != null && recipeDmb >= dmbHighMin) return 'text-red-500'
    else return 'text-green-500'
  }

  const dmRangeClassByNutrientName = (nutrientName) => {
    let nutrient = recipeNutrients.value.find(nutrient => nutrient.name == nutrientName)
    if (!nutrient) return ''

    let recipeDm = null
    let dmLowMax = null
    let dmHighMin = null

    let multiplier = 1
    if (adjustForEnergyDmbOver4k.value) multiplier = dryMatterBasisMultiplier.value

    if (petLifePhase.value == 'Puppy') {
      dmLowMax = nutrient.dm_low_max_puppy === null ? null : roundToDecimals(nutrient.dm_low_max_puppy * multiplier, 4)
      dmHighMin = nutrient.dm_high_min_puppy === null ? null : roundToDecimals(nutrient.dm_high_min_puppy * multiplier, 4)
    } else {
      dmLowMax = nutrient.dm_low_max_adult_dog === null ? null : roundToDecimals(nutrient.dm_low_max_adult_dog * multiplier, 4)
      dmHighMin = nutrient.dm_high_min_adult_dog === null ? null : roundToDecimals(nutrient.dm_high_min_adult_dog * multiplier, 4)
    }
    if (dmLowMax == null && dmHighMin == null) return ''

    switch (nutrientName) {
      case 'Cystine': {
        recipeDm = roundHundredth(recipeModel.nutrientDryMatterPer1000Kcal(nutrientName) + recipeModel.nutrientDryMatterPer1000Kcal('Methionine'))
        break
      }
      case 'Tyrosine': {
        recipeDm = roundHundredth(recipeModel.nutrientDryMatterPer1000Kcal(nutrientName) + recipeModel.nutrientDryMatterPer1000Kcal('Phenylalanine'))
        break
      }
      case 'Vitamin B-12': {
        recipeDm = roundToDecimals(recipeModel.nutrientDryMatterPer1000Kcal(nutrientName), 4)
        break
      }
      default: {
        recipeDm = roundHundredth(recipeModel.nutrientDryMatterPer1000Kcal(nutrientName))
        break
      }
    }

    console.log('nutrientName', nutrientName, 'recipeDm', recipeDm, 'dmLowMax', dmLowMax, 'dmHighMin', dmHighMin)

    if (dmLowMax != null && recipeDm <= dmLowMax) return 'text-yellow-500'
    else if (dmHighMin != null && recipeDm >= dmHighMin) return 'text-red-500'
    else return 'text-green-500'
  }

  const caPRatioLabel = () => {
    if (recipeModel.caPRatio.value * 100 <= pet.value.dietary_per_kg_need.ca_p_ratio_low_max) return 'Low'
    else if (recipeModel.caPRatio.value * 100 >= pet.value.dietary_per_kg_need.ca_p_ratio_high_min) return 'High'
    else return 'Optimal'
  }

  const caPRatioClass = () => {
    if (recipeModel.caPRatio.value * 100 <= pet.value.dietary_per_kg_need.ca_p_ratio_low_max) return 'text-yellow-500'
    else if (recipeModel.caPRatio.value * 100 >= pet.value.dietary_per_kg_need.ca_p_ratio_high_min) return 'text-red-500'
    else return 'text-green-500'
  }

  const cellKey = (v1, v2 = '', v3 = '') => {
    return v1+'-'+v2+'-'+v3
  }


  const ingredientHeaderKey = (ingredient)  => {
    return 'head-'+ingredient.id
  }

  const ingredientTotalKey = (ingredient)  => {
    return 'total-'+ingredient.id
  }

  const ingredientPercentKey = (ingredient)  => {
    return 'percent-'+ingredient.id
  }

  const ingredientGramsDryMatterKey = (ingredient)  => {
    return 'grams-dry-matter-'+ingredient.id
  }

  const recipeNutrientKey = (recipe, nutrient)  => {
    return recipe.id+'-'+nutrient.id
  }

  const ingredientBodyKey = (ingredient)  => {
    return 'body-'+ingredient.id
  }

  const recipeIngredientKey = (recipe, ingredient)  => {
    return recipe.id+'-'+ingredient.id
  }

  const petProfileAnalyzerMacroItemKey = (index) => {
    return 'pet-profile-analizer-macro-'+index.toString()
  }

  const petProfileAnalyzerMineralItemKey = (index) => {
    return 'pet-profile-analizer-mineral-'+index.toString()
  }

  const petNutrientVitaminKey = (nutrient) => {
    return 'pet-nutrient-vitamin-'+nutrient.id
  }

  const petNutrientFatKey = (nutrient) => {
    return 'pet-nutrient-fat-'+nutrient.id
  }

  const petNutrientAminoAcidKey = (nutrient) => {
    return 'pet-nutrient-amino-acid-'+nutrient.id
  }

  const petNutrientOtherKey = (nutrient) => {
    return 'pet-nutrient-other-'+nutrient.id
  }

  const goToEditRecipe = () => {
    router.replace({ name: 'EditRecipe', params: { id: recipe.value.id } })
  }
</script>
