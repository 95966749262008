<template>
  <div id="ShowRecipe" class="container max-w-4xl mx-auto pt-10 pb-20 px6">

    <div class="flex flex-wrap justify-between mt-4 mb-6 mx-4 md:mx-0">
      <div class="basis-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goBack()">
          Back
        </button>
      </div>
      <div class="basis-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goGroceryList()">
          Grocery List
        </button>
      </div>
      <div class="basis-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goToStep1()">
          Cook Now
        </button>
      </div>
    </div>

    <div class="text-gray-900 text-xl text-center">{{ recipe.name }}</div>
    <div v-if="recipeSizesLoaded && recipeSizes.length" class="flex items-center justify-center">
      <select class="
              appearance-none
              block
              w-500px
              bg-white
              text-gray-700
              border
              border-gray-400
              rounded
              py-3
              px-4
              leading-tight
              focus:outline-none
              focus:bg-white
              focus:border-gray-500
              "
              v-model="selRecipeSize"
              @change="goToShowRecipe(selRecipeSize)"
      >
        <option v-for="r in recipeSizes" :key="selAltRecipeKey(r)" :value="r">{{ altSizeName(r) }}</option>
      </select>
    </div>
    <div class="bg-innogreen w-full h-1"></div>

    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full">
        <span v-for="(line, i) in getStringLines(recipe.description)" :key="descLineKey(recipe, i)">
          {{ line }}
          <br>
        </span>
      </div>
    </div>

    <div v-if="recipe.web_video_id" class="flex flex-wrap mt-4 mb-6">
      <iframe width="560" height="315" :src="webVideoEmbedSrc(recipe)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>

    <div v-if="recipe.description2 && recipe.description2.length > 0" class="flex flex-wrap mt-4 mb-4">
      <div class="w-full">
        <span v-for="(line, i) in getStringLines(recipe.description2)" :key="desc2LineKey(recipe, i)">
          {{ line }}
          <br>
        </span>
      </div>
    </div>

    <div v-if="recipe.ingredients.length > 0" class="pt-2 pb-5">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg max-h-[700px]">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="sticky top-0 text-xs text-white uppercase bg-innogreen">
                  <tr>
                      <th scope="col" class="pl-4 pr-2 py-6">
                          Ingredient name
                      </th>
                      <th scope="col" class="px-2 py-6">
                          Grams
                      </th>
                      <th scope="col" class="px-2 py-6">
                          Avg Cost
                      </th>
                      <th scope="col" class="pl-2 pr-4 py-6">
                          Notes
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th scope="row" class="md:hidden pl-4 pr-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <Popper :content="ingredientNameFullBatch" :disabled="ingredientNameFullBatch.length < ingredientMaxChars">
                        {{ $filters.strLimit(ingredientNameFullBatch, ingredientMaxChars) }}
                      </Popper>
                    </th>
                    <th scope="row" class="hidden md:table-cell pl-4 pr-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {{ ingredientNameFullBatch }}
                    </th>
                    <td class="px-2 py-4" :class="{ 'text-red-500': ingredientGramsExceedRecipe, 'text-innogreen': !ingredientGramsExceedRecipe }">
                      {{ totalDisplay }}
                    </td>
                    <td class="px-2 py-3">
                      ${{ recipeCost }}
                    </td>
                    <td class="pl-2 pr-4 py-4">
                      &nbsp;
                    </td>
                  </tr>
                  <tr v-for="(ingredient, index) in recipe.ingredients" :key="recipeIngredientKey(recipe, ingredient)"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="md:hidden break-words pl-4 pr-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        <Popper :content="ingredient.name" :disabled="ingredient.name.length < ingredientMaxChars">
                          {{ $filters.strLimit(ingredient.name, ingredientMaxChars) }}
                        </Popper>
                      </th>
                      <th scope="row" class="hidden md:table-cell break-words pl-4 pr-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ ingredient.name }}
                      </th>
                      <td class="px-2 py-4">
                        {{ ingredient.amount.total_g }}
                      </td>
                      <td class="px-2 py-3">
                        ${{ ingredientCost(ingredient) }}
                      </td>
                      <td class="pl-2 pr-4 py-4">
                        <a v-if="ingredient.unsafe" @click="showUnsafe(ingredient)"><i class="fa-solid fa-skull-crossbones fa-xl text-red-500 pr-2"></i></a>
                        <a v-if="ingredient.possibly_unsafe" @click="showPossiblyUnsafe(ingredient)"><i class="fa-solid fa-triangle-exclamation fa-xl text-innoorange pr-2"></i></a>
                        <span v-if="ingredient.notes.length > 0">
                          <a v-if="ingredient.notes.length > 0" @click="showIngredientNotes(ingredient)">
                            <i class="fa-solid fa-comment-dots fa-xl text-innogreen pr-2"></i>
                            <br class="md:hidden">
                            {{ $filters.strLimit(ingredient.notes[0].msg, 12) }}
                          </a>
                        </span>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>

    <div id="fb-root"></div>
    <div v-if="commentUrl != ''" class="fb-comments" :data-href="commentUrl" data-width="100%" data-numposts="5"></div>
    <div style="font-size: 0.7rem;">*If using Brave browser then please disable shields to enable Facebook comments.</div>

  </div>
</template>

<script setup>
  import { onMounted, ref, computed, watch, nextTick } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import RecipeService from '../../services/recipe.service'
  import _ from 'lodash'
  import Swal from 'sweetalert2'
  import Popper from "vue3-popper"

  const route = useRoute()
  const router = useRouter()

  let recipe = ref({name: '...', description: '...', ingredients: []})
  let errors = ref([])
  let ingredientMaxChars = ref(14)
  let ingredientNameFullBatch = ref("MAXIMUM batch size for model#CP3A is 2898 grams")
  let recipeSizes = ref([])
  let selRecipeSize = ref(null)
  let recipeSizesLoaded = ref(false)
  let commentUrl = ref('')

  // Watch for changes in route
  watch(() => route.params, (newParams, oldParams) => {
    // i'm not sure if this is still needed, but i believe it helped us
    // with some issue at some point so i'm leaving it for now
    if (newParams.id !== undefined && newParams.id !== oldParams.id) {
      fullFacebookAndCommentsSetup()
      getRecipe()
    }
  }, { deep: true })

  onMounted(async () => {
    fullFacebookAndCommentsSetup()
    getRecipe()
  })

  const fullFacebookAndCommentsSetup = () => {
    commentUrl.value = process.env.VUE_APP_URL + 'account/recipe/' + route.params.id
    loadFacebookSDK()
    renderFacebookComments()
  }

  const loadFacebookSDK = () => {
    // Check if SDK is already loaded
    if (document.getElementById('facebook-jssdk')) {
      return;
    }

    window.fbAsyncInit = function() {
      // eslint-disable-next-line
      window.FB.init({
        appId            : '3352436855063048',
        xfbml            : true,
        version          : 'v20.0'
      });
    };

    // Create script tag for the Facebook SDK
    let script = document.createElement('script');
    script.id = 'facebook-jssdk';
    script.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v20.0&appId=3352436855063048';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }
  
  const renderFacebookComments = async () => {
    if (typeof window.FB !== 'undefined') {
      await nextTick() // Wait for the DOM to update
      // eslint-disable-next-line
      window.FB.XFBML.parse(); // Parse the updated content
    }
  }

  const getRecipe = async () => {
    try {
      let res = await RecipeService.getRecipeWithNutritionAndIngredientPricesAndParentAndChildren(route.params.id)
      recipe.value = res.data.recipe
      loadRecipeSizes(res.data.recipe)
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const loadRecipeSizes = (recipeObj) => {
    recipeSizesLoaded.value = false
    recipeSizes.value = []
    if (recipeObj.recipes.length) {
      recipeSizes.value = recipeObj.recipes
      recipeSizes.value.push(recipeObj)
      selRecipeSize.value = recipeObj
    } else if(recipeObj.recipe !== null) {
      recipeSizes.value.push(recipeObj.recipe)
      for (let i = 0; i < recipeObj.recipe.recipes.length; i++) {
        let r = recipeObj.recipe.recipes[i]
        recipeSizes.value.push(r)
        if (r.id === recipeObj.id) {
          selRecipeSize.value = r
        }
      }
    }
    recipeSizesLoaded.value = true
  }

  const altSizeName = (recipeObj) => {
    if (recipeObj.recipe_multiplier == 0 || recipeObj.recipe_multiplier == 1) {
      return '6 lbs Batch Size (ChefPaw model # CP3A and C-PRO)'
    } else if (recipeObj.recipe_multiplier == 5) {
      return '32 lbs Batch Size (ChefPaw model # MAXIMUS)'
    } else { // 0.5 is the only other one
      return '3 lbs Batch Size (ChefPaw model # CP3A and C-PRO)'
    }
  }

  const goToShowRecipe = (recipeObj) => {
    if (recipeObj.id != recipe.value.id) {
      router.push({ name: 'ShowRecipe', params: { id: recipeObj.id } })
    }
  }

  const selAltRecipeKey = (recipeObj) => {
    return 'size-' + recipeObj.id
  }

  const recipeIngredientKey = (recipeObj, ingredient)  => {
    return recipeObj.id+'-'+ingredient.id
  }

  const sumIngredientsGrams = () => {
    let sum = 0
    _.each(recipe.value.ingredients, function (ingredient) {
      sum += parseFloat(ingredient.amount.total_g)
    })
    return sum
  }

  const recipeIngredientsGramsDeviation = () => {
    return recipe.value.nutrition_breakdown.total_qty - sumIngredientsGrams()
  }

  const showUnsafe = (ingredient) => {
    let msg = "This ingredient has been found to be harmful to dogs.<br>"
    if (ingredient.notes) {
      for (let i = 0; i < ingredient.notes.length; i++) {
        msg += ingredient.notes[i].msg + "<br>"
      }
    }
    Swal.fire(ingredient.name, msg, 'error')
  }

  const showPossiblyUnsafe = (ingredient) => {
    let msg = "This ingredient is possibly unsafe or some dogs may be allergic.<br>"
    if (ingredient.notes) {
      for (let i = 0; i < ingredient.notes.length; i++) {
        msg += ingredient.notes[i].msg + "<br>"
      }
    }
    Swal.fire(ingredient.name, msg, 'warning')
  }

  const showIngredientNotes = (ingredient) => {
    if (ingredient.notes) {
      let msg = ''
      for (let i = 0; i < ingredient.notes.length; i++) {
        msg += ingredient.notes[i].msg + "<br>"
      }
      Swal.fire({
        title: ingredient.name,
        html: msg,
        icon: 'info',
        confirmButtonColor: '#F28705'
      })
    }
  }

  const getTotalGramsText = () => {
    let deviation = recipeIngredientsGramsDeviation()
    if (deviation > 0) return sumIngredientsGrams() + " (" + deviation + " under " + recipe.value.nutrition_breakdown.total_qty + ")"
    else if (deviation < 0) return sumIngredientsGrams() + " (" + (deviation * -1) + " over " + recipe.value.nutrition_breakdown.total_qty + ")"
    else return recipe.value.nutrition_breakdown.total_qty
  }

  const goToStep1 = () => {
    router.push({ name: 'RecipeSteps', params: { id: recipe.value.id, step: 1 } })
  }

  // const goToMakeBatch = () => {
  //   router.push({ name: 'RecipesBatchSummary', params: { id: recipe.value.id } })
  // }

  const goGroceryList = () => {
    router.push({ name: 'RecipeGroceryList', params: { id: recipe.value.id } })
  }

  const ingredientCost = (ingredient) => {
    let cost = 0
    if (ingredient.ingredient_prices.length > 0 && ingredient.ingredient_prices[0].cost_per_units != null) {
      // assuming price per 100g for now cuz that's all we're allowing admin to set so far
      let price_per_100g = parseFloat(ingredient.ingredient_prices[0].cost_per_units)
      let ingredient_g = parseFloat(ingredient.amount.total_g)
      let m = ingredient_g / 100
      cost = price_per_100g * m
    }
    cost = Math.round(cost * 100) / 100
    return cost.toFixed(2)
  }

  const calcRecipeCost = () => {
    let total_cost = 0;
    for (var i = 0; i < recipe.value.ingredients.length; i++) {
      let ingredient = recipe.value.ingredients[i]
      if (ingredient.ingredient_prices.length > 0 && ingredient.ingredient_prices[0].cost_per_units != null) {
        // assuming price per 100g for now cuz that's all we're allowing admin to set so far
        let price_per_100g = parseFloat(ingredient.ingredient_prices[0].cost_per_units)
        let ingredient_g = parseFloat(ingredient.amount.total_g)
        let m = ingredient_g / 100
        let cost = price_per_100g * m
        // console.log('ingredient, g, cost per 100g, cost', ingredient.name, ingredient_g, price_per_100g, cost)
        total_cost += cost
      }
    }
    return Math.round(total_cost * 100) / 100
  }

  const ingredientGramsExceedRecipe = computed({
    get: () => sumIngredientsGrams() > recipe.value.nutrition_breakdown.total_qty
  })

  const totalDisplay = computed({
      get: () => getTotalGramsText()
  })

  const recipeCost = computed({
    get: () => calcRecipeCost()
  })

  const goBack = () => {
    router.go(-1)
  }

  const getStringLines = (str) => {
    if (!str) return []
    return str.split("\n")
  }

  const webVideoEmbedSrc = (recipe) => {
    return 'https://www.youtube.com/embed/' + recipe.web_video_id
  }

  const descLineKey = (recipe, i) => {
    return 'description-' + i + '-' + recipe.id
  }

  const desc2LineKey = (recipe, i) => {
    return 'description2-' + i + '-' + recipe.id
  }
</script>
