<template>
  <div id="Register">
    <TopNavigation />

    <div class="flex-1 h-full max-w-4xl mx-auto bg-white rounded-lg shadow-xl mt-20 mb-20">
      <div class="flex flex-col md:flex-row">
        <div class="h-32 md:h-auto md:w-1/2">
          <img class="object-cover w-full h-full" src="@/assets/dog_carrot.jpg"
            alt="ChefPaw with Carrot" />
        </div>
        <div class="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
          <div class="w-full">
            <h1 class="mb-4 text-2xl font-bold text-center text-gray-700">
              Register
            </h1>
            <div class="mt-4">
              <TextInput 
                  label="First Name"
                  :labelColor="black"
                  placeholder="Sam"
                  v-model:input="firstName"
                  inputType="text"
                  :error="errors.first_name ? errors.first_name[0] : ''"
              />
            </div>
            <div class="pt-4">
              <TextInput 
                  label="Last Name"
                  :labelColor="black"
                  placeholder="Doe"
                  v-model:input="lastName"
                  inputType="text"
                  :error="errors.last_name ? errors.last_name[0] : ''"
              />
            </div>
            <div class="pt-4">
              <TextInput 
                  label="Email"
                  :labelColor="black"
                  placeholder="sam.doe@domain.com"
                  v-model:input="email"
                  inputType="email"
                  :error="errors.email ? errors.email[0] : ''"
              />
            </div>
            <div class="pt-4">
              <TextInput 
                  label="Password"
                  :labelColor="black"
                  placeholder="password123?"
                  v-model:input="password"
                  inputType="password"
                  :error="errors.password ? errors.password[0] : ''"
              />
            </div>
            <div class="pt-4">
              <TextInput 
                  label="Confirm Password"
                  :labelColor="black"
                  placeholder="password123?"
                  v-model:input="confirmPassword"
                  inputType="password"
                  :error="errors.password_confirmation ? errors.password_confirmation[0] : ''"
              />
            </div>
            <button class="block w-full mt-6 px-4 py-3 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-innoorange border border-transparent rounded-lg active:bg-innoorange hover:bg-innoorange-hover focus:outline-none focus:shadow-outline-innoorange"
              type="submit"
              @click="register" :disabled="isLoading">
              <svg v-if="isLoading" class="w-5 h-5 animate-spin ml-auto mr-auto" fill="none"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      fill="currentColor"></path>
              </svg>
              <span v-if="!isLoading">Register</span>
            </button>

            <div class="mt-4 text-center">
              <p class="text-sm">Already have an account?
                <router-link to="login" class="text-blue-600 no-underline hover:underline">
                  Login
                </router-link>
                </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterSection 
      topText="By creating a free ChefPaw account, you agree to receive exclusive updates, offers, and personalized content via email from ChefPaw. You can unsubscribe at any time."
    />
  </div>
</template>

<script setup>
  import Swal from 'sweetalert2'
  import { useRouter } from 'vue-router'
  import { ref } from 'vue';
  import TextInput from '../components/global/TextInput.vue'
  import TopNavigation from '../components/structure/TopNavigation.vue'
  import FooterSection from '../components/structure/FooterSection.vue'
  import { useToast } from 'vue-toastification'
  import UserService from '../services/user.service'

  const router = useRouter()
  const toast = useToast()

  let errors = ref([])
  let firstName = ref(null)
  let lastName = ref(null)
  let email = ref(null)
  let password = ref(null)
  let confirmPassword = ref(null)
  let isLoading = ref(false)

  const register = async () => {
    errors.value = []

    try {
      isLoading.value = true

      await UserService.register({
        first_name: firstName.value,
        last_name: lastName.value,
        email: email.value,
        password: password.value,
        password_confirmation: confirmPassword.value
      })

      Swal.fire({
          title: 'Registration Successful!',
          text: 'Your free account has been created. Please check your email for a verification link. You will now be redirected to the login page.',
          icon: 'success',
          confirmButtonColor: '#F28705'
        }).then(() => {
          isLoading.value = false
          router.push({ name: 'login' })
        })
    } catch(err) {
      isLoading.value = false
      toast.error(err.response.data.message)
      errors.value = err.response.data.errors
    }
  }
</script>