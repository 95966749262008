<template>
  <div id="AddUsdaIngredient" class="container max-w-4xl mx-auto pt-20 pb-20 px6">

    <div class="flex flex-wrap justify-between mt-4 mb-6 mx-4 md:mx-0">
        <div class="basis-1/4">
            <button class="
            block
            w-full
            bg-innoorange
            hover:bg-innoorange-hover
            text-white
            rounded-sm
            py-3
            text-sm
            tracking-wide  
            "
            type="submit"
            @click="goBack()">
            Back
            </button>
        </div>
        <div class="w-1/4 ml-auto">
          <button class="
            block
            w-full
            bg-innoorange
            hover:bg-innoorange-hover
            text-white
            rounded-sm
            py-3
            text-sm
            tracking-wide  
          "
            type="submit"
            @click="scrollToAddIngredient()">
            Add Ingredient
        </button>
      </div>
    </div>

    <div class="text-gray-900 text-3xl text-center">Ingriedients</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    
    <div class="pt-5 pb-5">
      <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
      <div class="relative">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
          </div>
          <input v-model="query" @keyup.enter="searchIngredients" type="text" class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search ChefPaw Ingredients..." required>
          <button class="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            type="submit"
            @click="searchIngredients" :disabled="searching">
            <svg v-if="searching" class="w-5 h-5 animate-spin ml-auto mr-auto" fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    fill="currentColor"></path>
            </svg>
            <span v-if="!searching">Search</span>
          </button>
      </div>
    </div>

    <div v-if="ingredients.length > 0">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="px-6 py-3">
                          Ingredient name
                      </th>
                      <th scope="col" class="px-6 py-3"></th>
                      <th scope="col" class="px-6 py-3">
                          Nutrition
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(ingredient, index) in ingredients" :key="ingredient.id"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ ingredient.name }}
                      </th>
                      <td v-if="canEditRecipe(ingredient)" class="px-6 py-4">
                        <div v-if="!ingredient.loading">
                          <a href="javascript:void(0)" @click="destroyIngredient(ingredient)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Delete</a>
                        </div>
                        <div v-if="ingredient.loading" class="font-medium" role="status">
                          Deleting...
                        </div>
                      </td>
                      <td v-if="!canEditRecipe(ingredient)" class="px-6 py-4"></td>
                      <td class="px-6 py-4">
                        <a href="javascript:void(0)" @click="goToIngredient(ingredient)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">View</a>
                        <span v-if="canEditRecipe(ingredient)">
                          | <a href="javascript:void(0)" @click="goToEditIngredient(ingredient)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
                        </span>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>

    <div class="flex flex-wrap mt-4 mb-6" ref="addIngredientRef">
      <div class="w-full md:w-3/4 px-3">
        <TextInput 
            placeholder="Add Ingredient Name"
            v-model:input="newIngredientName"
            inputType="text"
            :error="errors.name ? errors.name[0] : ''"
        />
      </div>
      <div class="w-full md:w-1/4 px-3 pt-2">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="addIngredient()">
          Save New Ingredient
        </button>
      </div>
    </div>

  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import IngredientService from '../../services/ingredient.service'
  import TextInput from '@/components/global/TextInput.vue'
  import Swal from 'sweetalert2'
  import { useToast } from "vue-toastification"
  import { useUserStore } from '../../store/user-store'

  const router = useRouter()
  const userStore = useUserStore()
  const toast = useToast()

  let searching = ref(false)
  let query = ref(null)
  let ingredients = ref([])
  let addIngredientRef = ref(null)
  let newIngredientName = ref(null)
  let errors = ref([])

  onMounted(async () => {
      await getIngredients()
  })

  const searchIngredients = async () => {
    searching.value = true
    try {
      let res = await IngredientService.searchIngredientsByName(query.value)
      ingredients.value = res.data.ingredients
      searching.value = false
    } catch (err) {
      errors.value = err.response.data.errors
      searching.value = false
    }
  }

  const getIngredients = async () => {
    searching.value = true
    try {
      let res = await IngredientService.listIngredients()
      ingredients.value = res.data.ingredients
      searching.value = false
    } catch (err) {
      errors.value = err.response.data.errors
      searching.value = false
    }
  }

  const destroyIngredient = async (ingredient) => {
    ingredient.loading = true
    Swal.fire({
      title: 'Are you sure?',
      text: ingredient.name + "will be permanently deleted.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F28705',
      cancelButtonColor: '#618C03',
      confirmButtonText: 'Yes, delete it!'
    }).then( async (result) => {
      if (result.isConfirmed) {
        try {
          await IngredientService.adminDeleteIngredient(ingredient.id)
          searchIngredients()
          ingredient.loading = false
        } catch (err) {
          if (err.response !== undefined && err.response.data !== undefined && err.response.data.message !== undefined) {
            toast.error(err.response.data.message)
          } else {
            toast.error('There was an error deleting the ingredient. If this persists, please contact support.')
          }
          ingredient.loading = false
        }
      }
    })
  }

  const addIngredient = async () => {
    try {
      let res = await IngredientService.adminCreateIngredient(newIngredientName.value)
      goToEditIngredient(res.data.ingredient)
    } catch (err) {
      errors.value = err.response.data.errors
      searching.value = false
    }
  }

  const scrollToAddIngredient = () => {
      addIngredientRef.value.scrollIntoView({ behavior: 'smooth'})
  }

  const goToIngredient = (ingredient) => {
    router.push({ name: 'IngredientNutrition', params: { id: ingredient.id } })
  }

  const goToEditIngredient = (ingredient) => {
    router.push({ name: 'EditIngredient', params: { id: ingredient.id } })
  }

  const canEditRecipe = (recipe) => {
    return userStore.isAdmin || (userStore.isProfessional && recipe.user_id === userStore.id)
  }

  const goBack = () => {
    router.go(-1)
  }
</script>
