import Api from '../apis/api'
import CsrfApi from '../apis/csrf.api'

class UserService {
  async login (credentials) {
    await CsrfApi.getCookie()
    return Api.post('/api/login', credentials)
  }

  logout () {
    return Api.post('/api/logout')
  }

  register (data) {
    return Api.post('/api/register', data)
  }

  getMe () {
    return Api.get('/api/users/me')
  }

  adminGetUser (id) {
    return Api.get('api/admin/users/' + id)
  }

  adminAddUser (data) {
    return Api.post('api/admin/users', data)
  }

  adminSearchUsers (query, page = 1, sort_by = 'last_login', sort_order = 'desc') {
    return Api.get('api/admin/users?query=' + query + '&page=' + page + '&sort_by=' + sort_by + '&sort_order=' + sort_order)
  }

  adminBecomeUser (id) {
    return Api.post('api/admin/users/' + id + '/become')
  }

  adminSuspendUser (id) {
    return Api.post('api/admin/users/' + id + '/suspend')
  }

  adminUnsuspendUser (id) {
    return Api.post('api/admin/users/' + id + '/unsuspend')
  }

  sendVerificationEmail (email) {
    return Api.post('api/email/resend', { email: email })
  }

  verifyEmail (url) {
    return Api.post(url)
  }

  requestPasswordReset (email) {
    return Api.post('api/request-password-reset', { email: email })
  }

  resetPassword (token, email, password, password_confirmation) {
    return Api.post('api/reset-password', {
      token: token,
      email: email,
      password: password,
      password_confirmation: password_confirmation
    })
  }

  adminSetUserRole (userId, roleName) {
    return Api.post('api/admin/user-role/' + userId + '/' + roleName)
  }

  getRecipesSharedWithMe () {
    return Api.get('api/users/me/recipes_shared_with_me')
  }

  getSharedRecipe (recipeId) {
    return Api.get('api/users/me/shared_recipe/' + recipeId)
  }

  shareRecipe (recipeId, userEmails) {
    let data = new FormData();
    for (let i = 0; i < userEmails.length; i++) {
      data.append('user_emails[]', userEmails[i])
    }
    return Api.post('api/users/me/share_recipe/' + recipeId, data)
  }

  unshareRecipe (recipeId, userEmails) {
    let data = new FormData();
    for (let i = 0; i < userEmails.length; i++) {
      data.append('user_emails[]', userEmails[i])
    }
    return Api.post('api/users/me/unshare_recipe/' + recipeId, data)
  }

  getDietaryNeedsSharedWithMe () {
    return Api.get('api/users/me/dietary_per_kg_needs_shared_with_me')
  }

  getMyDietaryNeedsAndThoseSharedWithMe () {
    return Api.get('api/users/me/my_dietary_per_kg_needs_and_those_shared_with_me')
  }

  getSharedDietaryNeed (dietaryNeedId) {
    return Api.get('api/users/me/shared_dietary_per_kg_need/' + dietaryNeedId)
  }

  shareDietaryNeed (dietaryNeedId, userEmails) {
    let data = new FormData();
    for (let i = 0; i < userEmails.length; i++) {
      data.append('user_emails[]', userEmails[i])
    }
    return Api.post('api/users/me/share_dietary_per_kg_need/' + dietaryNeedId, data)
  }

  unshareDietaryNeed (dietaryNeedId, userEmails) {
    let data = new FormData();
    for (let i = 0; i < userEmails.length; i++) {
      data.append('user_emails[]', userEmails[i])
    }
    return Api.post('api/users/me/unshare_dietary_per_kg_need/' + dietaryNeedId, data)
  }
}

export default new UserService()
