<template>
  <div class="w-full bg-innogreen text-innocream p-10 text-center text-sm">
    <p v-if="topText" class="pb-4">{{ topText }}</p>
    <span class="font-bold">&copy; {{ currentYear }} Chefpaw LLC</span><br />This app is protected by US Utility Patent #63/482,925 and PCT/US2024/014261, WO 2024/163901 (tel:2024/163901) A2.
    <p class="font-bold pt-2">Disclaimer for Suggested ChefPaw Recipes and DIY Customization:</p>
    <p class="py-2">Information displayed in the ChefPaw app does not replace the need to consult a licensed Veterinarian about the proper diet for your pet. Always consult a certified Veterinary Nutritionist for guidance in customizing recipes for the needs of any pet. For advice regarding customized recipes as part of treating a medical condition, you absolutely must contact a Board Certified Veterinary Nutrionist. Please contact us if you need help finding a Nutritionist.</p>
    <p class="py-2">The suggested Chefpaw recipes are general recipes based on commonly available ingredients from grocery stores in the USA.  All pet food recipes will require some nutritional supplementation in order to be considered complete & balanced. Chefpaw’s dietary supplement is included with Chefpaw suggested recipes in order to supplement only the most common nutritional deficiencies that will result from the most common fresh ingredients. To add other brands of supplements or new ingredients, please email support@chefpaw.com (mailto:support@chefpaw.com) and include the details on the product or ingredient that you'd like us to add free of charge.</p>
    <p class="py-2">These suggested recipes and nutritional calculations have not been evaluated by the AAFCO, NRC or USDA, and ChefPaw has no affiliation with those organizations. ChefPaw cannot guarantee that its suggested recipes will be adequate for the specific needs of your particular pet. Nutritional determinations of Low/Optimal/High found in the ChefPaw app are general calculations made based on limited profile information such as species, age, weight, and activity level. This should be considered general information and not a substitute for professional advice. Recipe selection based off of your pets unique history should only be obtained from a licensed veterinarian.</p>
  </div>
</template>

<script setup>
  import { ref, defineProps, toRefs } from 'vue'

  const props = defineProps({
    topText: {
      type: String,
      default: ''
    }
  })

  const { topText } = toRefs(props)

  const currentYear = ref(new Date().getFullYear())
</script>