<template>
  <div id="AddUsdaIngredient" class="container max-w-5xl mx-auto pt-20 pb-20 px6">

    <div class="flex flex-wrap mt-4 mb-6 mx-4 md:mx-4">
      <div class="w-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goBack()">
          Back
        </button>
      </div>
    </div>

    <div class="text-gray-900 text-3xl text-center">Recipe Nutrition:<br>{{ recipe.name }}</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>
    <div class="text-gray-900 text-xl text-center">This page is intended to be used on a large desktop or iPad screen.</div>

    <div v-if="recipe.ingredients.length > 0" class="pt-5 pb-5">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg max-h-[700px]">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="sticky top-0 text-xs text-white uppercase bg-innogreen" style="z-index: 2;">
                  <tr>
                    <th scope="col" class="sticky left-0 px-2 py-3 text-center bg-innogreen z-[2]"></th>  
                    <th scope="col" class="px-2 py-3">
                        GRAND TOTALS:
                    </th>
                    <th scope="col" class="px-2 py-3">
                        AS FED:
                    </th>
                    <th scope="col" class="px-2 py-3">
                        DRY MATTER BASIS:
                    </th>
                    <th scope="col" class="px-2 py-3">
                        PER 1000 KCALS:
                    </th>
                    <th scope="col" class="px-2 py-3">
                        INGREDIENTS:
                    </th>
                    <th scope="col" class="px-2 py-3" v-for="i in (recipe.ingredients.length - 1)" :key="'header-'+i"></th>
                  </tr>
              </thead>
              <tbody>
                <tr class="sticky top-[40px] bg-gray-100 border-b dark:bg-gray-900 dark:border-gray-700" style="z-index: 3;">
                    <th scope="col" class="sticky left-0 px-2 py-1 text-center bg-gray-100 shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]">
                        Nutrient / Data Point
                    </th>
                    <th scope="col" class="px-2 py-1">
                        <Popper :content="recipe.name" :disabled="recipe.name.length < nutrientMaxChars">
                          Recipe
                        </Popper>
                    </th>
                    <th scope="col" class="px-2 py-1"></th>
                    <th scope="col" class="px-2 py-1"></th>
                    <th scope="col" class="px-2 py-1"></th>
                    <th scope="col" class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="ingredientHeaderKey(ingredient)">
                        <Popper :content="ingredient.name" :disabled="ingredient.name.length < nutrientMaxChars">
                          {{ $filters.strLimit(ingredient.name, nutrientMaxChars) }}
                        </Popper>
                    </th>
                </tr>
                <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" class="sticky left-0 px-2 px-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center bg-gray-50 shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]">
                    Total Grams
                  </th>
                  <td scope="row" class="px-2 px-1">
                    {{ sumIngredientsGrams() }}
                  </td>
                  <td scope="row" class="px-2 px-1"></td>
                  <td scope="row" class="px-2 px-1"></td>
                  <td scope="row" class="px-2 px-1"></td>
                  <td class="px-2 px-1" v-for="ingredient in recipe.ingredients" :key="ingredientTotalKey(ingredient)">
                    {{ ingredient.amount.total_g }}
                  </td>
                </tr>  
                <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th scope="row" class="sticky left-0 px-2 px-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center bg-white shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]">% of recipe</th>
                  <td scope="row" class="px-2 px-1">100%</td>
                  <td scope="row" class="px-2 px-1"></td>
                  <td scope="row" class="px-2 px-1"></td>
                  <td scope="row" class="px-2 px-1"></td>
                  <td class="px-2 px-1" v-for="ingredient in recipe.ingredients" :key="ingredientPercentKey(ingredient)">
                    {{ ingredient.amount.percent_of_recipe }}%
                  </td>
                </tr>
                <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                  <th class="sticky left-0 px-2 px-1 font-medium text-gray-900 whitespace-nowrap dark:text-white bg-white shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]">MACROS</th>
                  <td class="px-2 py-1">&nbsp;</td>
                  <td class="px-2 py-1">&nbsp;</td>
                  <td class="px-2 py-1">&nbsp;</td>
                  <td class="px-2 py-1">&nbsp;</td>
                  <td class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="'macros-header-'+ingredient.id"></td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th scope="row" class="sticky left-0 px-2 px-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center bg-white shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]">
                    Total Dry Matter
                  </th>
                  <td scope="row" class="px-2 px-1">
                    {{ recipeModel.totalGramsDryMatterRounded }}g
                  </td>
                  <td scope="row" class="px-2 px-1">
                    {{ batchTotalDryMatterPercentAsFedRounded }}%
                  </td>
                  <td scope="row" class="px-2 px-1"></td>
                  <td scope="row" class="px-2 px-1">
                    {{ recipeModel.dryMatterPer1000KcalFormatted() }}
                  </td>
                  <td class="px-2 px-1" v-for="ingredient in recipe.ingredients" :key="ingredientDmKey(ingredient)">
                    {{ recipeModel.ingredientGramsDryMatterFormatted(ingredient)}}
                  </td>
                </tr>
                <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700" v-for="(nutrient, index) in recipeModel.nutrientsByCategoryName('Macros')" 
                    :key="recipeNutrientKey(recipe, nutrient)"
                    :class="index % 2 === 0 ? 'bg-gray-50' : 'bg-white'">
                    <th scope="row" 
                        class="sticky left-0 md:hidden px-2 px-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]"
                        :class="index % 2 === 0 ? 'bg-gray-50' : 'bg-white'">
                        <Popper :content="nutrient.name" :disabled="nutrient.name.length < nutrientMaxChars">
                            {{ $filters.strLimit(nutrient.name, nutrientMaxChars) }}
                        </Popper>
                    </th>
                    <th scope="row" 
                        class="sticky left-0 hidden md:table-cell px-2 px-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]"
                        :class="index % 2 === 0 ? 'bg-gray-50' : 'bg-white'">
                        {{ recipeModel.nutrientNameFormatted(nutrient.name) }}
                    </th>
                    <td class="px-2 px-1">
                        {{ recipeModel.nutrientTotalFormatted(nutrient.name) }}
                    </td>
                    <td class="px-2 px-1">
                        {{ recipeModel.nutrientAsFedFormatted(nutrient.name) }}
                    </td>
                    <td class="px-2 px-1" :class="[recipeModel.dmbRangeClassByNutrientName(nutrient.name)]">
                        {{ recipeModel.nutrientDryMatterBasisFormatted(nutrient.name) }}
                    </td>
                    <td class="px-2 px-1" :class="[recipeModel.dmRangeClassByNutrientName(nutrient.name)]">
                        {{ recipeModel.nutrientDryMatterPer1000KcalFormatted(nutrient.name) }}
                    </td>
                    <td class="px-2 px-1" v-for="ingredient in recipe.ingredients" :key="ingredientBodyKey(ingredient)">
                        {{ recipeModel.ingredientNutrientAmountFormatted(ingredient, nutrient.name) }}
                    </td>
                </tr>
                <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" class="sticky left-0 px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center bg-gray-50 shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]">
                    kcal from protein
                  </th>
                  <td scope="row" class="px-2 px-1">
                    {{ recipeModel.kcalFromProteinRounded }}kcal
                  </td>
                  <td scope="row" class="px-2 px-1">
                    {{ recipeModel.kcalFromProteinPercentAsFedRounded }}%
                  </td>
                  <td scope="row" class="px-2 px-1"></td>
                  <td scope="row" class="px-2 px-1"></td>
                  <td class="px-2 px-1" v-for="ingredient in recipe.ingredients" :key="'kcal-from-protein-'+ingredient.id"></td>
                </tr>

                <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th scope="row" class="sticky left-0 px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center bg-white shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]">
                    kcal from fat
                  </th>
                  <td scope="row" class="px-2 px-1">
                    {{ recipeModel.kcalFromFatRounded }}kcal
                  </td>
                  <td scope="row" class="px-2 px-1">
                    {{ recipeModel.kcalFromFatPercentAsFedRounded }}%
                  </td>
                  <td scope="row" class="px-2 px-1"></td>
                  <td scope="row" class="px-2 px-1"></td>
                  <td class="px-2 px-1" v-for="ingredient in recipe.ingredients" :key="'kcal-from-fat-'+ingredient.id"></td>
                </tr>

                <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" class="sticky left-0 px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center bg-gray-50 shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]">
                    kcal from carbs
                  </th>
                  <td scope="row" class="px-2 px-1">
                    {{ recipeModel.kcalFromCarbsRounded }}kcal
                  </td>
                  <td scope="row" class="px-2 px-1">
                    {{ recipeModel.kcalFromCarbsPercentAsFedRounded }}%
                  </td>
                  <td scope="row" class="px-2 px-1"></td>
                  <td scope="row" class="px-2 px-1"></td>
                  <td class="px-2 px-1" v-for="ingredient in recipe.ingredients" :key="'kcal-from-carbs-'+ingredient.id"></td>
                </tr>

                <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th scope="row" class="sticky left-0 px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center bg-white shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]">
                    keto ratio [fat/(protein +carbs)]
                  </th>
                  <td scope="row" class="px-2 px-1">
                    {{ recipeModel.ketoRatioFormatted() }}
                  </td>
                  <td scope="row" class="px-2 px-1"></td>
                  <td scope="row" class="px-2 px-1"></td>
                  <td scope="row" class="px-2 px-1"></td>
                  <td class="px-2 px-1" v-for="ingredient in recipe.ingredients" :key="'keto-ratio-'+ingredient.id"></td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th class="sticky left-0 px-2 px-1 font-medium text-gray-900 whitespace-nowrap dark:text-white bg-white shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]">MINERALS:</th>
                  <td class="px-2 py-1">&nbsp;</td>
                  <td class="px-2 py-1">&nbsp;</td>
                  <td class="px-2 py-1">&nbsp;</td>
                  <td class="px-2 py-1">&nbsp;</td>
                  <td class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="'minerals-header-'+ingredient.id"></td>
                </tr>
                <tr v-for="(nutrient, index) in recipeModel.nutrientsByCategoryName('Minerals')" 
                    :key="recipeNutrientKey(recipe, nutrient)"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                    <th scope="row" 
                        class="sticky left-0 md:hidden px-2 px-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]"
                        :class="index % 2 === 0 ? 'bg-gray-50' : 'bg-white'">
                      <Popper :content="nutrient.name" :disabled="nutrient.name.length < nutrientMaxChars">
                        {{ $filters.strLimit(nutrient.name, nutrientMaxChars) }}
                      </Popper>
                    </th>
                    <th scope="row" 
                        class="sticky left-0 hidden md:table-cell px-2 px-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]"
                        :class="index % 2 === 0 ? 'bg-gray-50' : 'bg-white'">
                      {{ recipeModel.nutrientNameFormatted(nutrient.name) }}
                    </th>
                    <td class="px-2 px-1">
                      {{ recipeModel.nutrientTotalFormatted(nutrient.name) }}
                    </td>
                    <td class="px-2 px-1">
                      {{ recipeModel.nutrientAsFedFormatted(nutrient.name) }}
                    </td>
                    <td class="px-2 px-1" :class="[recipeModel.dmbRangeClassByNutrientName(nutrient.name)]">
                      {{ recipeModel.nutrientDryMatterBasisFormatted(nutrient.name) }}
                    </td>
                    <td class="px-2 px-1" :class="[recipeModel.dmRangeClassByNutrientName(nutrient.name)]">
                      {{ recipeModel.nutrientDryMatterPer1000KcalFormatted(nutrient.name) }}
                    </td>
                    <td class="px-2 px-1" v-for="ingredient in recipe.ingredients" :key="ingredientBodyKey(ingredient)">
                      {{ recipeModel.ingredientNutrientAmountFormatted(ingredient, nutrient.name) }}
                    </td>
                </tr>
                <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" class="sticky left-0 px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center bg-gray-50 shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]">
                    Ca/P Ratio
                  </th>
                  <td scope="row" class="px-2 py-1">
                    {{ recipeModel.caPRatioFormatted() }}
                  </td>
                  <td scope="row" class="px-2 py-1"></td>
                  <td scope="row" class="px-2 py-1"></td>
                  <td scope="row" class="px-2 py-1"></td>
                  <td class="px-2 px-1" v-for="ingredient in recipe.ingredients" :key="'cap-ratio-'+ingredient.id"></td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th scope="row" class="sticky left-0 px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center bg-white shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]">
                    Ca/Vitamin C Ratio
                  </th>
                  <td scope="row" class="px-2 py-1">
                    {{ recipeModel.caVitaminCRatioFormatted() }}
                  </td>
                  <td scope="row" class="px-2 py-1"></td>
                  <td scope="row" class="px-2 py-1"></td>
                  <td scope="row" class="px-2 py-1"></td>
                  <td class="px-2 px-1" v-for="ingredient in recipe.ingredients" :key="'ca-vitc-ratio-'+ingredient.id"></td>
                </tr>
                <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" class="sticky left-0 px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center bg-gray-50 shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]">
                    Zn:Cu ratio
                  </th>
                  <td scope="row" class="px-2 py-1">
                    {{ recipeModel.znCuRatioFormatted() }}
                  </td>
                  <td scope="row" class="px-2 py-1"></td>
                  <td scope="row" class="px-2 py-1"></td>
                  <td scope="row" class="px-2 py-1"></td>
                  <td class="px-2 px-1" v-for="ingredient in recipe.ingredients" :key="'zn-cu-ratio-'+ingredient.id"></td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th class="sticky left-0 px-2 px-1 font-medium text-gray-900 whitespace-nowrap dark:text-white bg-white shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]">VITAMINS:</th>
                  <td class="px-2 py-1">&nbsp;</td>
                  <td class="px-2 py-1">&nbsp;</td>
                  <td class="px-2 py-1">&nbsp;</td>
                  <td class="px-2 py-1">&nbsp;</td>
                  <td class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="'vitamins-header-'+ingredient.id"></td>
                </tr>
                <tr v-for="(nutrient, index) in recipeModel.nutrientsByCategoryName('Vitamins')" 
                    :key="recipeNutrientKey(recipe, nutrient)"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                    <th scope="row" 
                        class="sticky left-0 md:hidden px-2 px-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]"
                        :class="index % 2 === 0 ? 'bg-gray-50' : 'bg-white'">
                      <Popper :content="nutrient.name" :disabled="nutrient.name.length < nutrientMaxChars">
                        {{ $filters.strLimit(nutrient.name, nutrientMaxChars) }}
                      </Popper>
                    </th>
                    <th scope="row" 
                        class="sticky left-0 hidden md:table-cell px-2 px-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]"
                        :class="index % 2 === 0 ? 'bg-gray-50' : 'bg-white'">
                      {{ recipeModel.nutrientNameFormatted(nutrient.name) }}
                    </th>
                    <td class="px-2 px-1">
                      {{ recipeModel.nutrientTotalFormatted(nutrient.name) }}
                    </td>
                    <td class="px-2 px-1">
                      {{ recipeModel.nutrientAsFedFormatted(nutrient.name) }}
                    </td>
                    <td class="px-2 px-1" :class="[recipeModel.dmbRangeClassByNutrientName(nutrient.name)]">
                      {{ recipeModel.nutrientDryMatterBasisFormatted(nutrient.name) }}
                    </td>
                    <td class="px-2 px-1" :class="[recipeModel.dmRangeClassByNutrientName(nutrient.name)]">
                      {{ recipeModel.nutrientDryMatterPer1000KcalFormatted(nutrient.name) }}
                    </td>
                    <td class="px-2 px-1" v-for="ingredient in recipe.ingredients" :key="ingredientBodyKey(ingredient)">
                      {{ recipeModel.ingredientNutrientAmountFormatted(ingredient, nutrient.name) }}
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th class="sticky left-0 px-2 px-1 font-medium text-gray-900 whitespace-nowrap dark:text-white bg-white shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]">FATS:</th>
                  <td class="px-2 py-1">&nbsp;</td>
                  <td class="px-2 py-1">&nbsp;</td>
                  <td class="px-2 py-1">&nbsp;</td>
                  <td class="px-2 py-1">&nbsp;</td>
                  <td class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="'fats-header-'+ingredient.id"></td>
                </tr>
                <tr v-for="(nutrient, index) in recipeModel.nutrientsByCategoryName('Fats')" 
                    :key="recipeNutrientKey(recipe, nutrient)"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                    <th scope="row" 
                        class="sticky left-0 md:hidden px-2 px-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]"
                        :class="index % 2 === 0 ? 'bg-gray-50' : 'bg-white'">
                      <Popper :content="nutrient.name" :disabled="nutrient.name.length < nutrientMaxChars">
                        {{ $filters.strLimit(nutrient.name, nutrientMaxChars) }}
                      </Popper>
                    </th>
                    <th scope="row" 
                        class="sticky left-0 hidden md:table-cell px-2 px-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]"
                        :class="index % 2 === 0 ? 'bg-gray-50' : 'bg-white'">
                      {{ recipeModel.nutrientNameFormatted(nutrient.name) }}
                    </th>
                    <td class="px-2 px-1">
                      {{ recipeModel.nutrientTotalFormatted(nutrient.name) }}
                    </td>
                    <td class="px-2 px-1">
                      {{ recipeModel.nutrientAsFedFormatted(nutrient.name) }}
                    </td>
                    <td class="px-2 px-1" :class="[recipeModel.dmbRangeClassByNutrientName(nutrient.name)]">
                      {{ recipeModel.nutrientDryMatterBasisFormatted(nutrient.name) }}
                    </td>
                    <td class="px-2 px-1" :class="[recipeModel.dmRangeClassByNutrientName(nutrient.name)]">
                      {{ recipeModel.nutrientDryMatterPer1000KcalFormatted(nutrient.name) }}
                    </td>
                    <td class="px-2 px-1" v-for="ingredient in recipe.ingredients" :key="ingredientBodyKey(ingredient)">
                      {{ recipeModel.ingredientNutrientAmountFormatted(ingredient, nutrient.name) }}
                    </td>
                </tr>
                <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" class="sticky left-0 px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center bg-gray-50 shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]">
                    Omega 6:3 ratio
                  </th>
                  <td scope="row" class="px-2 py-1">
                    {{ recipeModel.omega63RatioFormatted() }}
                  </td>
                  <td scope="row" class="px-2 py-1"></td>
                  <td scope="row" class="px-2 py-1"></td>
                  <td scope="row" class="px-2 py-1"></td>
                  <td class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="'omega-ratio-'+ingredient.id"></td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th class="sticky left-0 px-2 px-1 font-medium text-gray-900 whitespace-nowrap dark:text-white bg-white shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]">AMINO ACIDS:</th>
                  <td class="px-2 py-1">&nbsp;</td>
                  <td class="px-2 py-1">&nbsp;</td>
                  <td class="px-2 py-1">&nbsp;</td>
                  <td class="px-2 py-1">&nbsp;</td>
                  <td class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="'amino-acids-header-'+ingredient.id"></td>
                </tr>
                <tr v-for="(nutrient, index) in recipeModel.nutrientsByCategoryName('Amino Acids')" 
                    :key="recipeNutrientKey(recipe, nutrient)"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                    <th scope="row" 
                        class="sticky left-0 md:hidden px-2 px-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]"
                        :class="index % 2 === 0 ? 'bg-gray-50' : 'bg-white'">
                      <Popper :content="nutrient.name" :disabled="nutrient.name.length < nutrientMaxChars">
                        {{ $filters.strLimit(nutrient.name, nutrientMaxChars) }}
                      </Popper>
                    </th>
                    <th scope="row" 
                        class="sticky left-0 hidden md:table-cell px-2 px-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]"
                        :class="index % 2 === 0 ? 'bg-gray-50' : 'bg-white'">
                      {{ recipeModel.nutrientNameFormatted(nutrient.name) }}
                    </th>
                    <td class="px-2 px-1">
                      {{ recipeModel.nutrientTotalFormatted(nutrient.name) }}
                    </td>
                    <td class="px-2 px-1">
                      {{ recipeModel.nutrientAsFedFormatted(nutrient.name) }}
                    </td>
                    <td class="px-2 px-1" :class="[recipeModel.dmbRangeClassByNutrientName(nutrient.name)]">
                      {{ recipeModel.nutrientDryMatterBasisFormatted(nutrient.name) }}
                    </td>
                    <td class="px-2 px-1" :class="[recipeModel.dmRangeClassByNutrientName(nutrient.name)]">
                      {{ recipeModel.nutrientDryMatterPer1000KcalFormatted(nutrient.name) }}
                    </td>
                    <td class="px-2 px-1" v-for="ingredient in recipe.ingredients" :key="ingredientBodyKey(ingredient)">
                      {{ recipeModel.ingredientNutrientAmountFormatted(ingredient, nutrient.name) }}
                    </td>
                </tr>
                <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                  <th class="sticky left-0 px-2 px-1 font-medium text-gray-900 whitespace-nowrap dark:text-white bg-white shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]">OTHER:</th>
                  <td class="px-2 py-1">&nbsp;</td>
                  <td class="px-2 py-1">&nbsp;</td>
                  <td class="px-2 py-1">&nbsp;</td>
                  <td class="px-2 py-1">&nbsp;</td>
                  <td class="px-2 py-1" v-for="ingredient in recipe.ingredients" :key="'other-header-'+ingredient.id"></td>
                </tr>
                <tr v-for="(nutrient, index) in recipeModel.nutrientsByCategoryName(null)" 
                    :key="recipeNutrientKey(recipe, nutrient)"
                    :class="index % 2 == 0 ? 'bg-white border-b dark:bg-gray-900 dark:border-gray-700' : 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700'">
                    <th scope="row" 
                        class="sticky left-0 md:hidden px-2 px-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]"
                        :class="index % 2 === 0 ? 'bg-gray-50' : 'bg-white'">
                      <Popper :content="nutrient.name" :disabled="nutrient.name.length < nutrientMaxChars">
                        {{ $filters.strLimit(nutrient.name, nutrientMaxChars) }}
                      </Popper>
                    </th>
                    <th scope="row" 
                        class="sticky left-0 hidden md:table-cell px-2 px-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]"
                        :class="index % 2 === 0 ? 'bg-gray-50' : 'bg-white'">
                      {{ recipeModel.nutrientNameFormatted(nutrient.name) }}
                    </th>
                    <td class="px-2 px-1">
                      {{ recipeModel.nutrientTotalFormatted(nutrient.name) }}
                    </td>
                    <td class="px-2 px-1">
                      {{ recipeModel.nutrientAsFedFormatted(nutrient.name) }}
                    </td>
                    <td class="px-2 px-1" :class="[recipeModel.dmbRangeClassByNutrientName(nutrient.name)]">
                      {{ recipeModel.nutrientDryMatterBasisFormatted(nutrient.name) }}
                    </td>
                    <td class="px-2 px-1" :class="[recipeModel.dmRangeClassByNutrientName(nutrient.name)]">
                      {{ recipeModel.nutrientDryMatterPer1000KcalFormatted(nutrient.name) }}
                    </td>
                    <td class="px-2 px-1" v-for="ingredient in recipe.ingredients" :key="ingredientBodyKey(ingredient)">
                      {{ recipeModel.ingredientNutrientAmountFormatted(ingredient, nutrient.name) }}
                    </td>
                </tr>
                <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                  <td class="px-2 py-1 text-sm text-gray-900 dark:text-white break-words" :colspan="recipe.ingredients.length + 5">
                    *** totals for this nutrient are under construction and should be independently verified. Consulting with a certified veterinary nutritionist is always recommended when customizing a recipe.
                  </td>
                </tr>
              </tbody>
          </table>
      </div>
    </div>


  </div>
</template>

<script setup>
  import { onMounted, ref, computed } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import RecipeService from '../../services/recipe.service'
  import Popper from "vue3-popper"
  import _ from 'lodash'
  import { useRecipeModel } from '@/composables/useRecipeModel'

  const route = useRoute()
  const router = useRouter()

  let recipe = ref({ingredients: []})
  let errors = ref([])
  let nutrientMaxChars = ref(12)

  const recipeModel = useRecipeModel(computed(() => recipe.value))

  const batchTotalDryMatterPercentAsFedRounded = computed({
    get: () => roundTenth((recipeModel.totalGramsDryMatter.value / sumIngredientsGrams()) * 100)
  })

  onMounted(async () => {
      await getRecipe()
  })

  const roundTenth = (value) => {
    return Math.round(value * 10) / 10 // Rounds to the nearest tenth
  }

  const getRecipe = async () => {
    try {
      let res = await RecipeService.getRecipeWithNutritionAndHiddenNutrients(route.params.id)
      recipe.value = res.data.recipe
      console.log(res.data)
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const ingredientHeaderKey = (ingredient)  => {
    return 'head-'+ingredient.id
  }

  const ingredientPercentKey = (ingredient)  => {
    return 'percent-'+ingredient.id
  }

  const ingredientDmKey = (ingredient)  => {
    return 'dm-'+ingredient.id
  }

  const ingredientTotalKey = (ingredient)  => {
    return 'total-'+ingredient.id
  }
  
  const ingredientBodyKey = (ingredient)  => {
    return 'body-'+ingredient.id
  }

  const recipeNutrientKey = (recipe, nutrient)  => {
    return recipe.id+'-'+nutrient.id
  }

  const sumIngredientsGrams = () => {
    let sum = 0
    _.each(recipe.value.ingredients, function (ingredient) {
      sum += parseFloat(ingredient.amount.total_g)
    })
    return sum
  }

  const goBack = () => {
    router.go(-1)
  }

</script>

<style scoped>
/* Add these styles to ensure proper z-index stacking */
.sticky {
  position: sticky;
  z-index: 1;
}

thead.sticky {
  z-index: 2;
}
</style>
