<template>
  <div id="AdminEditIngredient" class="container max-w-4xl mx-auto pt-20 pb-20 px6">

    <div class="flex md:flex md:flex-grow flex-row justify-end space-x-4 mb-5">
      <div class="w-1/4">
        <button class="
          block
          w-full
          bg-innogreen
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="addNote()">
          Add Note
        </button>
      </div>
      <div class="w-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="saveIngredient()">
          Save
        </button>
      </div>
    </div>

    <div class="text-gray-900 text-3xl text-center">{{ ingredient.name }} - Ingredient Nutrition Details</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>
    
    <div class="text-gray-900 text-xl">
      <TextInput 
          label="Name"
          placeholder="Potato, Boiled"
          v-model:input="ingredient.name"
          inputType="text"
          @keyup.enter="saveIngredient()"
      />
    </div>
    <div class="text-gray-900 text-xl mt-4">
      <TextInput 
          label="Price per 100g"
          placeholder="0"
          v-model:input="ingredient.ingredient_prices[0].cost_per_units"
          inputType="text"
          @keyup.enter="saveIngredient()"
      />
    </div>

    <div v-if="ingredient.notes.length > 0" class="text-gray-900 text-xl mt-5">
      <label class="block uppercase tracking-wide text-xs font-bold mb-2 text-gray-900">Notes</label>
      <ul>
        <li v-for="note in ingredient.notes" :key="note.id">
          <a href="javascript:void(0)" @click="deleteNote(note.id)"><i class="fa-solid fa-trash text-red-500 pr-4"></i></a>{{ note.msg }}
        </li>
      </ul>
    </div>

    <div v-if="ingredient.nutrition_breakdown.nutrients.length > 0" class="pt-5 pb-5">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="px-6 py-3">
                          Nutrient
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Amount
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Unit
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">Heat Sensitivity</th>
                    <td class="px-6 py-4">
                      <input type="checkbox" id="heat_sensitivity" v-model="heatSensitivity" />
                    </td>
                    <td class="px-6 py-4">
                      &nbsp;
                    </td>
                  </tr>
                  <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">UNSAFE</th>
                    <td class="px-6 py-4">
                      <input type="checkbox" id="unsafe" v-model="ingredient.unsafe" />
                    </td>
                    <td class="px-6 py-4">
                      &nbsp;
                    </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">Possibly Unsafe</th>
                    <td class="px-6 py-4">
                      <input type="checkbox" id="possibly_unsafe" v-model="ingredient.possibly_unsafe" />
                    </td>
                    <td class="px-6 py-4">
                      &nbsp;
                    </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">Cook Well</th>
                    <td class="px-6 py-4">
                      <input type="checkbox" id="cook_well" v-model="cookWell" />
                    </td>
                    <td class="px-6 py-4">
                      &nbsp;
                    </td>
                  </tr>
                  <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">Total<span v-if="ingredient.nutrition_breakdown.total_qty == 0" class="text-red-500 font-bold"> - SET ABOVE 0, LIKELY 100</span></th>
                    <td class="px-6 py-4">
                      <input type="text" v-model="ingredient.nutrition_breakdown.total_qty" @keyup.enter="saveIngredient">
                    </td>
                    <td class="px-6 py-4">
                      {{ ingredient.nutrition_breakdown.total_unit_type }}
                    </td>
                  </tr>
                  <tr v-for="(nutrient, index) in ingredient.nutrition_breakdown.nutrients" :key="nutrient.id"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ nutrient.name }}
                      </th>
                      <td class="px-6 py-4">
                        <input type="text" v-model="nutrient.amount.qty" @keyup.enter="saveIngredient">
                      </td>
                      <td class="px-6 py-4">
                        {{ nutrient.amount.unit_type }}
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>


  </div>
</template>

<script setup>
  import { onMounted, ref, computed } from 'vue'
  import { useRoute } from 'vue-router'
  import IngredientService from '../../services/ingredient.service'
  import TextInput from '../../components/global/TextInput.vue'
  import { useToast } from "vue-toastification"
  import Swal from 'sweetalert2'

  const route = useRoute()
  const toast = useToast()

  let ingredient = ref({
    name: '...',
    notes: [],
    nutrition_breakdown: {
      nutrients: []
    },
    ingredient_prices: [
      {cost_per_units: 0}
    ]
  })
  let errors = ref([])
  let loading = ref(true)

  onMounted(async () => {
      await getIngredient()
  })

  const heatSensitivity = computed({
      get: () => ingredient.value.heat_sensitivity == 1 ? true : false,
      set: (val) => val === true ? ingredient.value.heat_sensitivity = 1 : ingredient.value.heat_sensitivity = 0
  })

  const cookWell = computed({
      get: () => ingredient.value.cook_well == 1 ? true : false,
      set: (val) => val === true ? ingredient.value.cook_well = 1 : ingredient.value.cook_well = 0
  })

  const getIngredient = async () => {
    try {
      loading.value = true
      let res = await IngredientService.getIngredientWithPrices(route.params.id)
      ingredient.value = res.data.ingredient
      loading.value = false
    } catch (err) {
      errors.value = err.response.data.errors
      loading.value = false
    }
  }

  const saveIngredient = async () => {
    let data = new FormData();
    data.append('ingredient', JSON.stringify(ingredient.value))

    try {
      loading.value = true
      let res = await IngredientService.adminUpdateIngredient(ingredient.value.id, data)
      ingredient.value = res.data.ingredient
      loading.value = false
      toast.success(ingredient.value.name + " saved.")
    } catch (err) {
      errors.value = err.response.data.errors
      loading.value = false
    }
  }

  const addNote = async () => {
    Swal.fire({
      title: 'Enter your note below',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Save',
      showLoaderOnConfirm: true,
      preConfirm: (msg) => {
        return IngredientService.adminCreateNote(ingredient.value.id, msg)
          .then(response => {
            if (!response.status == 201) {
              throw new Error(response.message)
            }
            return response.data
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Error: ${error.response.data.message}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        ingredient.value = result.value.ingredient
        toast.success("Note added.")
        getIngredient()
      }
    })
  }

  const deleteNote = async (noteId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "This note will be permanently deleted.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F28705',
      cancelButtonColor: '#618C03',
      confirmButtonText: 'Yes, delete it!'
    }).then( async (result) => {
      if (result.isConfirmed) {
        try {
          await IngredientService.adminDeleteNote(ingredient.value.id, noteId)
          toast.success("Note deleted.")
          getIngredient()
        } catch (err) {
          console.log(err);
          errors.value = err.response.data.errors
        }
      }
    })
  }
</script>
