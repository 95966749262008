<template>
  <div id="PetRecipeNutrition" class="container max-w-4xl mx-auto pt-20 pb-20 px6">

    <div class="flex flex-wrap mt-4 mb-6 mx-4 md:mx-4">
      <div class="w-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goBack()">
          Back
        </button>
      </div>
    </div>

    <div class="text-gray-900 text-3xl text-center">{{ recipe.name }} for {{ pet.name }}</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <div class="text-center">
      <label class="relative inline-flex items-center cursor-pointer ml-2 mr-8">
        <input type="checkbox" value="true" class="sr-only peer" v-model="optimizeForEnergy">
        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-innogreen dark:peer-focus:ring-innogreen dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-innogreen"></div>
        <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Adjust serving size to meet caloric needs</span>
      </label>
    </div>

    <div class="text-gray-900 text-xl text-center">{{ pet.name }} <span class="font-bold">serving size = {{ petGramsPerDayRounded }} grams (approx. {{ petCupsPerDayText }}) of food</span>, divided into 2-3 meals per day. That amount of the recipe will result in the following nutritional profile for {{ pet.name }}.</div>
    <div v-if="servingSizeExceeds925PercentOfBodyWeight" class="text-gray-900 text-xl text-center mt-3">
      <span class="font-bold">WARNING:</span> This serving size exceeds 9.25% of your pets body weight because the recipe does not have enough calories to meet your pets caloric need. Please select a higher calorie recipe or edit the recipe accordingly. You can adjust the caloric need of your pet by adjusting the activity level in the pet profile.
    </div>
    
    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full">
        <span v-for='(line, i) in getStringLines(recipe.description)' :key="i">
          {{ line }}
          <br>
        </span>
      </div>
    </div>
    
    <div v-if="pet.daily_nutrition_breakdown != null" class="pt-5 pb-5">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg max-h-[700px]">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="sticky top-0 text-xs text-white uppercase bg-innogreen">
                  <tr>
                      <th scope="col" class="px-4 py-6">
                          Nutrient
                      </th>
                      <th scope="col" class="px-4 py-6">
                          Daily Need
                      </th>
                      <th scope="col" class="px-4 py-6">
                          Recipe Value
                      </th>
                      <th scope="col" class="px-4 py-6">
                          Nutrient Level
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(nutrient, index) in petNutrients" :key="nutrient.id"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="md:hidden px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        <Popper :content="recipeModel.nutrientNameFormatted(nutrient.name, true)" :disabled="recipeModel.nutrientNameFormatted(nutrient.name, true).length < nutrientMaxChars">
                          {{ $filters.strLimit(recipeModel.nutrientNameFormatted(nutrient.name, true), nutrientMaxChars) }}
                        </Popper>
                      </th>
                      <th scope="row" class="hidden md:table-cell px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ recipeModel.nutrientNameFormatted(nutrient.name, true) }}
                      </th>
                      <td class="px-4 py-4">
                        {{ Math.round(nutrient.amount.qty) }}{{ nutrient.amount.unit_type }}
                      </td>
                      <td class="px-4 py-4">
                        {{ Math.round(recipeNutrients[index].amount.qty * servingMultiplier) }}{{ recipeNutrients[index].amount.unit_type }}
                      </td>
                      <td class="px-4 py-4" :class="[rangeClass(index)]">
                        {{ rangeLabel(index) }}
                      </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th scope="row" class="px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Ca/P Ratio
                      </th>
                      <td class="px-4 py-4">
                        1:1 to 2:1
                      </td>
                      <td class="px-4 py-4">
                        {{ formattedRecipeCaPRatio }}
                      </td>
                      <td class="px-4 py-4" :class="[caPRatioClass()]">
                        {{ caPRatioLabel() }}
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
      <div class="px-4 py-4">
        <p>* An official high limit for dogs does not exist for this nutrient. A "high" level for this nutrient just means that it is higher than normal.</p>
        <p class="mt-2">** An official low OR high limit for dogs does not exist for this nutrient. A "low" or a "high" level for this nutrient just means that it is lower or higher than normal.</p>
        <p class="mt-2">*** Totals for this nutrient are under construction and should be independently verified. Consulting with a certified veterinary nutritionist is always recommended when customizing a recipe.</p>
        <p class="mt-2">****Regardless of the water content of this recipe, ALWAYS make sure your pets have access to clean drinking water 24/7/365</p>
      </div>
    </div>


  </div>
</template>

<script setup>
  import { onMounted, ref, computed } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import _ from 'lodash';
  import PetService from '../../services/pet.service.js'
  import RecipeService from '../../services/recipe.service.js'
  import CaPRatioService from '../../services/caPRatio.service.js'
  import Popper from "vue3-popper"
  import { useRecipeModel } from '@/composables/useRecipeModel'

  const route = useRoute()
  const router = useRouter()

  let pet = ref({name: '', daily_nutrition_breakdown: null})
  let petGramsPerDay = ref('')
  let recipe = ref({ nutrition_breakdown: { total_qty: 2980 }})
  let petNutrients = ref([])
  let recipeNutrients = ref([])
  let targetDeviations = ref([])
  let nutrientRanges = ref([]) // 1 for low, 2 for optimal, 3 for high
  let caPRatio = ref({low_max: 50, high_min: 150})
  let recipeCaAmount = ref(0)
  let recipePAmount = ref(0)
  let nutrientMaxChars = ref(12)
  let optimizeForEnergy = ref(false)
  // let errors = ref([])

  const recipeModel = useRecipeModel(computed(() => recipe.value))

  onMounted(async () => {
      Promise.all([PetService.getPetWithDailyNutritionAndDietaryPerKgNeed(route.params.petId), RecipeService.getRecipeWithNutrition(route.params.recipeId), getCaPRatio()]).then((values) => {
        pet.value = values[0].data.pet
        recipe.value = values[1].data.recipe
        petNutrients.value = []
        recipeNutrients.value = []
        targetDeviations.value = []
        nutrientRanges.value = []
        petGramsPerDay.value = pet.value.daily_nutrition_breakdown.total_qty
        const recipe_multiplier = petGramsPerDay.value / recipe.value.nutrition_breakdown.total_qty
        for (let i = 0; i < pet.value.daily_nutrition_breakdown.nutrients.length; i++) {
          let nutrient = pet.value.daily_nutrition_breakdown.nutrients[i]
          petNutrients.value.push(nutrient)
          if(!_.find(recipe.value.nutrition_breakdown.nutrients, {id: nutrient.id})) {
            let recipe_nutrient = structuredClone(nutrient)
            recipe_nutrient.amount.qty = 0
            recipeNutrients.value.push(recipe_nutrient)
          }
        }
        for (let i = 0; i < recipe.value.nutrition_breakdown.nutrients.length; i++) {
          let nutrient = recipe.value.nutrition_breakdown.nutrients[i]
          if (nutrient.amount.qty > 0) nutrient.amount.qty = Math.round(nutrient.amount.qty * recipe_multiplier * 1000) / 1000

          // maybe in the future we'll change how this works
          if (nutrient.name == 'Calcium, Ca') recipeCaAmount.value = nutrient.amount.qty
          else if (nutrient.name == 'Phosphorus, P') recipePAmount.value = nutrient.amount.qty

          recipeNutrients.value.push(nutrient)
          if(!_.find(pet.value.daily_nutrition_breakdown.nutrients, {id: nutrient.id})) {
            let pet_nutrient = structuredClone(nutrient)
            pet_nutrient.amount.qty = 0
            petNutrients.value.push(pet_nutrient)
          }
        }
        petNutrients.value = _.sortBy(petNutrients.value, 'default_sort_order')
        recipeNutrients.value = _.sortBy(recipeNutrients.value, 'default_sort_order')
        for (let i = 0; i < petNutrients.value.length; i++) {
          let petNutrient = petNutrients.value[i]
          if (petNutrient.amount.qty == 0) {
            let deviation = 'N/A'
            targetDeviations.value.push(deviation)
            nutrientRanges.value.push(2)
          } else {
            let recipeNutrient = recipeNutrients.value[i]
            let deviation = Math.round(((recipeNutrient.amount.qty * servingMultiplier.value) / petNutrient.amount.qty) * 100)
            let deviationStr = deviation + '%'

            let dpknNutrient = _.find(pet.value.dietary_per_kg_need.nutrition_breakdown.nutrients, function(n) {
              return n.id == recipeNutrient.id
            })

            if (dpknNutrient === undefined) nutrientRanges.value.push(2)
            else if (deviation <= dpknNutrient.amount.low_max) nutrientRanges.value.push(1)
            else if (deviation >= dpknNutrient.amount.high_min) nutrientRanges.value.push(3)
            else nutrientRanges.value.push(2)

            targetDeviations.value.push(deviationStr)
          }
        }
      });
  })

  const servingMultiplier = computed(() => {
    if (optimizeForEnergy.value) {
      let petEnergy = petNutrients.value.find(nutrient => nutrient.name == 'Energy')
      let recipeEnergy = recipeNutrients.value.find(nutrient => nutrient.name == 'Energy')
      if (petEnergy == undefined || recipeEnergy == undefined) return 1
      return petEnergy.amount.qty / recipeEnergy.amount.qty
    }
    return 1
  })

  const petGramsPerDayRounded = computed({
      get: () => Math.round(petGramsPerDay.value * servingMultiplier.value)
  })

  const petCupsPerDayRounded = computed({
      get: () => Math.round((petGramsPerDay.value * servingMultiplier.value) / 226)
  })

  const petCupsPerDayText = computed({
      get: () => petCupsPerDayRounded.value == 1 ? petCupsPerDayRounded.value + ' cup' : petCupsPerDayRounded.value + ' cups'
  })

  const recipeCaPRatio = computed({
    get: () => Math.round((recipeCaAmount.value / recipePAmount.value) * 100)
  })

  const formattedRecipeCaPRatio = computed({
    get: () => (recipeCaPRatio.value / 100).toFixed(1) + ':1'
  })

  const servingSizeExceeds925PercentOfBodyWeight = computed(() => {
    let percentOfBodyWeight = (petGramsPerDayRounded.value / (pet.value.weight_kg * 1000)) * 100
    return percentOfBodyWeight > 9.25
  })

  // const recipeTotalGrams = computed({
  //     get: () => Math.round(recipe.value.nutrition_breakdown.total_qty)
  // })

  const getCaPRatio = async () => {
    try {
      let res = await CaPRatioService.getDefault()
      caPRatio.value = res.data.ca_p_ratio
    } catch (err) {
      console.log('Error getting Ca/P Ratio.')
    }
  }

  const rangeLabel = (index) => {
    if (nutrientRanges.value[index] == 1) return 'Low'
    else if (nutrientRanges.value[index] == 2) return 'Optimal'
    else return 'High'
  }

  const rangeClass = (index) => {
    if (nutrientRanges.value[index] == 1) return 'text-yellow-500'
    else if (nutrientRanges.value[index] == 2) return 'text-green-500'
    else return 'text-red-500'
  }

  const caPRatioLabel = () => {
    if (recipeCaPRatio.value <= caPRatio.value.low_max) return 'Low'
    else if (recipeCaPRatio.value >= caPRatio.value.high_min) return 'High'
    else return 'Optimal'
  }

  const caPRatioClass = () => {
    if (recipeCaPRatio.value <= caPRatio.value.low_max) return 'text-yellow-500'
    else if (recipeCaPRatio.value >= caPRatio.value.high_min) return 'text-red-500'
    else return 'text-green-500'
  }

  const getStringLines = (str) => {
    if (!str) return []
    return str.split("\n")
  }

  const goBack = () => {
    router.go(-1)
  }

  // const getPet = async () => {
  //   try {
  //     let res = await PetService.getPetWithDailyNutrition(route.params.petId)
  //     pet.value = res.data.pet
  //     console.log(res.data)
  //   } catch (err) {
  //     errors.value = err.response.data.errors
  //   }
  // }

  // const getRecipe = async () => {
  //   try {
  //     let res = await RecipeService.getRecipe(route.params.recipeId)
  //     recipe.value = res.data.recipe
  //     console.log(res.data)
  //   } catch (err) {
  //     errors.value = err.response.data.errors
  //   }
  // }
</script>
