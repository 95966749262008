<template>
  <div id="AddUsdaIngredient" class="container max-w-4xl mx-auto pt-20 pb-20 px6">

    <div class="flex flex-wrap justify-between mt-4 mb-6 mx-4 md:mx-0">
      <div class="basis-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goBack()">
          Back
        </button>
      </div>
      <div v-if="pets.length > 0" class="basis-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goAdvancedEditor()">
          Advanced Editor
        </button>
      </div>
      <div class="basis-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="editRecipe()">
          Save
        </button>
      </div>
    </div>

    <div class="text-gray-900 text-3xl text-center">{{ recipe.name }}</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full text-xl">
        <TextInput 
            label="Recipe name"
            placeholder="sam.doe@m.com"
            v-model:input="recipe.name"
            inputType="text"
            @keyup.enter="updateRecipeName()"
        />
      </div>
    </div>

    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full">
        <label class="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2">Species</label>
        <div class="flex flex-wrap">
          <label v-for="specie in species" :key="specie.id" class="relative inline-flex items-center mr-5 cursor-pointer mr-8 my-4">
            <input type="checkbox" value="" class="sr-only peer" v-model="specie.selected">
            <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-innogreen dark:peer-focus:ring-innogreen dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-innogreen"></div>
            <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{{ specie.name }}</span>
          </label>
        </div>
      </div>
    </div>

    <div v-for="specie in species" :key="specieTagsKey(specie)">
      <div v-if="specie.selected" class="flex flex-wrap mt-4 mb-6">
        <div class="w-full">
          <label class="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2">{{ specie.name }} Categories</label>
          <div class="flex flex-wrap">
            <label v-for="tag in speciesRecipeTags[specie.slug]" :key="specieTagKey(specie, tag)" class="relative inline-flex items-center mr-5 cursor-pointer mr-8 my-4">
              <input type="checkbox" value="" class="sr-only peer" v-model="tag.selected">
              <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-innogreen dark:peer-focus:ring-innogreen dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-innogreen"></div>
              <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{{ tagName(tag) }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full">
        <TextArea 
            label="Description"
            placeholder="Please enter some information about your recipe."
            v-model:description="recipe.description"
        />
      </div>
    </div>
    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full md:w-1/4 md:pr-3">
        <label 
          class="
              block 
              uppercase 
              tracking-wide 
              text-xs 
              font-bold 
              mb-2
              text-gray-900
          "
        >
            ChefPaw Speed Setting
        </label>
        <select class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border
                border-gray-400
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none
                focus:bg-white
                focus:border-gray-500
                "
                v-model="recipe.speed"
        >
          <option v-for="(speed, index) in speeds" :key="index" :selected="speed == recipe.speed" :value="speed">{{ speed }}</option>
        </select>
      </div>
      <div class="w-full md:w-3/4 md:pl-3 text-xl">
        <TextInput 
            label="YouTube Video ID"
            placeholder="ex: VkSMSbFV_q0"
            v-model:input="recipe.web_video_id"
            inputType="text"
        />
      </div>
    </div>
    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full">
        <TextArea 
            label="Description 2"
            v-model:description="recipe.description2"
        />
      </div>
    </div>

    <label v-if="userStore.isAdmin" class="relative inline-flex items-center mr-5 cursor-pointer">
      <input type="checkbox" value="" class="sr-only peer" v-model="recipeIsFree">
      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-innogreen dark:peer-focus:ring-innogreen dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-innogreen"></div>
      <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{{ recipeIsFree ? 'Available to Free Accounts' : 'Not Available to Free Accounts' }}</span>
    </label>

    <label class="relative inline-flex items-center mr-5 cursor-pointer">
      <input type="checkbox" value="" class="sr-only peer" v-model="recipe.is_shared">
      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-innogreen dark:peer-focus:ring-innogreen dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-innogreen"></div>
      <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{{ recipe.is_shared ? 'Shared Globally' : 'Private' }}</span>
    </label>

    <div v-if="canShareWithUsers" class="flex flex-wrap mt-4 mb-6">
      <div class="w-full text-xl">
        <TextInput 
            label="Share this Recipe with specific ChefPaw Users"
            placeholder="add 1 email at a time and press enter to send Recipe after each email is added"
            v-model:input="shareWithEmail"
            inputType="text"
            @keyup.enter="shareWithUser()"
        />
      </div>
    </div>

    <div v-if="canShareWithUsers" class="flex flex-wrap mt-4 mb-6">
      <div class="w-full">
        <div class="flex flex-wrap">
          <span v-for="email in sharedWithEmails" :key="email" class="inline-flex items-center mx-1 px-2 py-1 me-2 text-sm font-medium text-gray-900 bg-innoorange rounded dark:bg-innoorange dark:text-gray-900">
            {{ email }}
            <button @click="unshareWithUser(email)" type="button" class="inline-flex items-center p-1 ms-2 text-sm text-gray-900 bg-transparent rounded-sm hover:bg-innoorange-hover hover:text-gray-900 dark:hover:bg-innoorange-hover dark:hover:text-gray-300" data-dismiss-target="#badge-dismiss-default" aria-label="Unshare">
              <svg class="w-2 h-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
              </svg>
              <span class="sr-only">Unshare With {{ email }}</span>
            </button>
          </span>
        </div>
      </div>
    </div>

    <div v-if="recipe.ingredients.length > 0" class="pt-5 pb-5">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg max-h-[700px]">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="sticky top-0 text-xs text-white uppercase bg-innogreen">
                  <tr>
                      <th scope="col" class="px-6 py-6">
                          Ingredient name
                      </th>
                      <th scope="col" class="px-6 py-6">
                          Grams
                      </th>
                      <th scope="col" class="px-3 py-6">
                          Notes
                      </th>
                      <th scope="col" class="px-3 py-6">
                          Action
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      MAXIMUM batch size for model#CP3A is 2898 grams
                    </th>
                    <td class="px-6 py-4" :class="{ 'text-red-500': ingredientGramsDontMatchRecipe }">
                      {{ totalDisplay }}
                    </td>
                    <td class="px-3 py-4">
                      &nbsp;
                    </td>
                    <td class="px-3 py-4">
                      &nbsp;
                    </td>
                  </tr>
                  <tr v-for="(ingredient, index) in recipe.ingredients" :key="recipeIngredientKey(recipe, ingredient)"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ ingredient.name }}
                      </th>
                      <td class="px-6 py-4">
                        <input type="text" v-model="ingredient.amount.total_g" @keyup.enter="editRecipe">
                      </td>
                      <td class="px-3 py-4">
                        <a v-if="ingredient.unsafe" @click="showUnsafe(ingredient)"><i class="fa-solid fa-skull-crossbones fa-xl text-red-500 pr-4"></i></a>
                        <a v-if="ingredient.possibly_unsafe" @click="showPossiblyUnsafe(ingredient)"><i class="fa-solid fa-triangle-exclamation fa-xl text-innoorange pr-4"></i></a>
                        <span v-if="ingredient.notes.length > 0">
                          <a v-if="ingredient.notes.length > 0" @click="showIngredientNotes(ingredient)"><i class="fa-solid fa-comment-dots fa-xl text-innogreen pr-4"></i></a>
                          {{ $filters.strLimit(ingredient.notes[0].msg, 12) }}
                        </span>
                      </td>
                      <td class="px-3 py-4">
                        <div v-if="!ingredient.loading">
                          <a @click="removeIngredientAtIndex(index)" href="javascript:void(0)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Remove</a>
                        </div>
                        <div v-if="ingredient.loading" class="spinner-border animate-spin inline-block w-4 h-4 rounded-full" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>

    <div class="bg-innogreen w-full h-1"></div>

    <div class="text-gray-900 text-xl font-bold mt-3">Add Ingredients</div>
    <div class="text-gray-900 text-sm">Please email support@chefpaw.com if you cannot find an ingredient.</div>
    
    <div class="pt-5 pb-5">
      <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
      <div class="relative">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
          </div>
          <input v-model="query" @keyup.enter="searchIngredients" type="text" class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search ChefPaw Ingredients..." required>
          <button v-if="!searching" @click="searchIngredients" type="button" class="text-white absolute right-2.5 bottom-2.5 bg-innoorange hover:bg-innoorange-hover focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-innoorange dark:focus:ring-innoorange-hover">Search</button>
          <button v-if="searching" type="button" class="text-white absolute right-2.5 bottom-2.5 bg-innoorange hover:bg-innoorange-hover focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-innoorange dark:focus:ring-innoorange-hover">
            <div class="spinner-border animate-spin inline-block w-4 h-4 rounded-full" role="status">
              <svg class="w-5 h-5 animate-spin ml-auto mr-auto" fill="none"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      fill="currentColor"></path>
              </svg>
            </div>
          </button>
      </div>
    </div>

    <div v-if="ingredients.length > 0">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="px-3 py-3 max-w-[150px]">
                          Ingredient name
                      </th>
                      <th scope="col" class="px-2 py-3">
                          Notes
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Grams
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Action
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(ingredient, index) in ingredients" :key="ingredient.id"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="px-3 py-4 font-medium text-gray-900 dark:text-white">
                        {{ ingredient.name }}
                      </th>
                      <td class="px-2 py-4">
                        <a v-if="ingredient.possibly_unsafe" @click="showPossiblyUnsafe(ingredient)"><i class="fa-solid fa-triangle-exclamation fa-xl text-innoorange pr-4"></i></a>
                        <span v-if="ingredient.notes.length > 0">
                          <a @click="showIngredientNotes(ingredient)"><i class="fa-solid fa-comment-dots fa-xl text-innogreen pr-4"></i></a>
                          {{ $filters.strLimit(ingredient.notes[0].msg, 12) }}
                        </span>
                      </td>
                      <td class="px-6 py-4">
                        <input type="text" v-model="ingredient.total_g">
                      </td>
                      <td class="px-6 py-4">
                        <div v-if="!ingredient.loading">
                          <a v-if="ingredient.unsafe" @click="showUnsafe(ingredient)"><i class="fa-solid fa-skull-crossbones fa-xl text-red-500 pr-4"></i></a>
                          <a v-if="!ingredient.unsafe && !ingredient.added" @click="addIngredient(ingredient)" href="javascript:void(0)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Add</a>
                          <span v-if="ingredient.added" class="text-green">Added</span>
                        </div>
                        <div v-if="ingredient.loading" class="spinner-border animate-spin inline-block w-4 h-4 rounded-full" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        <div v-if="false" class="flex justify-center items-center">
                          <div class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>

  </div>
</template>

<script setup>
  import { onMounted, ref, computed } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import RecipeService from '../../services/recipe.service'
  import UserService from '../../services/user.service'
  import IngredientService from '../../services/ingredient.service'
  import TagService from '@/services/tag.service'
  import SpecieService from '@/services/specie.service'
  import PetService from '@/services/pet.service'
  import TextInput from '../../components/global/TextInput.vue'
  import TextArea from '@/components/global/TextArea.vue'
  import _ from 'lodash'
  import { useToast } from "vue-toastification"
  import Swal from 'sweetalert2'
  import { useUserStore } from '../../store/user-store'

  const route = useRoute()
  const router = useRouter()
  const toast = useToast()
  const userStore = useUserStore()

  let searching = ref(false)
  let recipe = ref({name: '...', description: '...', description2: '...', web_video_id: '...', speed: 1, ingredients: []})
  let recipeIsFree = ref(false)
  let query = ref(null)
  let ingredients = ref([])
  let errors = ref([])
  let speciesRecipeTags = ref({})
  let speeds = ref([1,2])
  let shareWithEmail = ref('')
  let sharedWithEmails = ref([])
  let species = ref([])
  let pets = ref([])

  onMounted(async () => {
    getPets()
    await getRecipe()
    // might be a more efficient way to do this in the future,
    // but for security we're making a separate call through
    // user controller
    if (userOwnsRecipe.value) getSharedRecipe()
    await getSpecies()
    let promises = []
    for (let i = 0; i < species.value.length; i++) {
      promises.push(getSpecieRecipeTags(species.value[i]))
    }
    await Promise.all(promises)
    setSelectedSpeciesRecipeTags()
  })

  const getRecipe = async () => {
    try {
      let res = await RecipeService.getRecipeWithNutrition(route.params.id)
      recipe.value = res.data.recipe
      recipeIsFree.value = recipe.value.tags.some(tag => tag.slug.en === 'free')
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const getSharedRecipe = async () => {
    try {
      let res = await UserService.getSharedRecipe(route.params.id)
      updateSharedWithEmails(res.data.recipe)
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const getSpecieRecipeTags = async (specie) => {
    try {
      let res = await TagService.listSpecieRecipeTags(specie.slug)
      speciesRecipeTags.value[specie.slug] = res.data.tags
    } catch (err) {
      toast.error('Failed to load ' + specie.name + ' Recipe Tags')
      errors.value = err.response.data.errors
    }
  }

  const getSpecies = async () => {
    try {
      let res = await SpecieService.listSpecies()
      species.value = res.data.species
      species.value = species.value.map(specie => ({
          ...specie,
          selected: recipe.value.species.some(selSpecie => selSpecie.id === specie.id)
      }));
    } catch (err) {
      console.log(err)
    }
  }

  const getPets = async () => {
    try {
      let res = await PetService.listPets()
      pets.value = res.data.pets
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const setSelectedSpeciesRecipeTags = () => {
    for (let i = 0; i < species.value.length; i++) {
      let specie = species.value[i]
      let specieRecipeTags = speciesRecipeTags.value[specie.slug]
      for (let k = 0; k < specieRecipeTags.length; k++) {
        if (recipe.value.tags.find(tag => tag.id == specieRecipeTags[k].id)) {
          speciesRecipeTags.value[specie.slug][k].selected = true
        } else {
          speciesRecipeTags.value[specie.slug][k].selected = false
        }
        }
    }
  }

  const searchIngredients = async () => {
    searching.value = true
    try {
      let res = await IngredientService.searchIngredientsByName(query.value)
      ingredients.value = res.data.ingredients
      searching.value = false
    } catch (err) {
      errors.value = err.response.data.errors
      searching.value = false
    }
  }

  const addIngredient = async (ingredient) => {
    let data = new FormData();
    data.append('ingredients', JSON.stringify([{id: ingredient.id, total_g: ingredient.total_g}]))

    try {
      let res = await RecipeService.addIngredients(recipe.value.id, data)
      recipe.value = res.data.recipe
      toast.success(ingredient.name + " added.")
    } catch (err) {
      if (err.response.data.errors && Array.isArray(err.response.data.errors)) errors.value = err.response.data.errors
      toast.error(err.response.data.message)
    }
  }

  const editRecipe = async () => {
    let specieIds = species.value.filter(specie => specie.selected).map(specie => specie.id)
    if (specieIds.length == 0) {
      Swal.fire({
        title: 'Error',
        text: 'Please select at least 1 Species',
        icon: 'error',
        confirmButtonColor: '#F28705'
      })
      return
    }

    let selSpeciesRecipeTags = {}
    for (let i = 0; i < species.value.length; i++) {
      let specie = species.value[i]
      selSpeciesRecipeTags[specie.slug] = speciesRecipeTags.value[specie.slug].filter(tag => tag.selected).map(tag => tag.name.en)
    }

    let data = new FormData();
    data.append('recipe', JSON.stringify(recipe.value))
    data.append('specie_ids', JSON.stringify(specieIds))
    data.append('species_recipe_tags', JSON.stringify(selSpeciesRecipeTags))
    data.append('is_free', recipeIsFree.value)

    try {
      let res = await RecipeService.updateRecipe(recipe.value.id, data)
      recipe.value = res.data.recipe
      setSelectedSpeciesRecipeTags()
      toast.success(recipe.value.name + " saved.")
    } catch (err) {
      if (err.response.data.errors && Array.isArray(err.response.data.errors)) errors.value = err.response.data.errors
      toast.error(err.response.data.message)
    }
  }

  const updateRecipeName = async () => {
    let data = new FormData();
    data.append('recipe', JSON.stringify({ name: recipe.value.name, description: recipe.value.description }))

    try {
      let res = await RecipeService.updateRecipe(recipe.value.id, data)
      recipe.value = res.data.recipe
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const removeIngredientAtIndex = async (index) => {
    recipe.value.ingredients.splice(index, 1)
    editRecipe()
  }

  const recipeIngredientKey = (recipe, ingredient)  => {
    return recipe.id+'-'+ingredient.id
  }

  const sumIngredientsGrams = () => {
    let sum = 0
    _.each(recipe.value.ingredients, function (ingredient) {
      sum += parseFloat(ingredient.amount.total_g)
    })
    return sum
  }

  const recipeIngredientsGramsDeviation = () => {
    return recipe.value.nutrition_breakdown.total_qty - sumIngredientsGrams()
  }

  const showUnsafe = (ingredient) => {
    let msg = "This ingredient has been found to be harmful to dogs.<br>"
    if (ingredient.notes) {
      for (let i = 0; i < ingredient.notes.length; i++) {
        msg += ingredient.notes[i].msg + "<br>"
      }
    }
    Swal.fire(ingredient.name, msg, 'error')
  }

  const showPossiblyUnsafe = (ingredient) => {
    let msg = "This ingredient is possibly unsafe or some dogs may be allergic.<br>"
    if (ingredient.notes) {
      for (let i = 0; i < ingredient.notes.length; i++) {
        msg += ingredient.notes[i].msg + "<br>"
      }
    }
    Swal.fire(ingredient.name, msg, 'warning')
  }

  const showIngredientNotes = (ingredient) => {
    if (ingredient.notes) {
      let msg = ''
      for (let i = 0; i < ingredient.notes.length; i++) {
        msg += ingredient.notes[i].msg + "<br>"
      }
      Swal.fire(ingredient.name, msg, 'info')
    }
  }

  const getTotalGramsText = () => {
    let deviation = recipeIngredientsGramsDeviation()
    if (deviation > 0) return sumIngredientsGrams() + " (" + deviation + " under " + recipe.value.nutrition_breakdown.total_qty + ")"
    else if (deviation < 0) return sumIngredientsGrams() + " (" + (deviation * -1) + " over " + recipe.value.nutrition_breakdown.total_qty + ")"
    else return recipe.value.nutrition_breakdown.total_qty
  }

  const tagName = (tag) => {
    return tag.name.en
  }

  const updateSharedWithEmails = (recipeWithSharedUsers) => {
    sharedWithEmails.value = []
    for (let i = 0; i < recipeWithSharedUsers.users_shared_with.length; i++) {
      sharedWithEmails.value.push(recipeWithSharedUsers.users_shared_with[i].email)
    }
  }

  

  const shareWithUser = async () => {
    if (!canShareWithUsers.value) {
      Swal.fire({
        title: 'Error',
        text: 'You are not authorized to share this recipe.',
        icon: 'error',
        confirmButtonColor: '#F28705'
      })
      return
    }
    let emails = [shareWithEmail.value]
    try {
      let res = await UserService.shareRecipe(recipe.value.id, emails)
      toast.success("Shared with " + shareWithEmail.value)
      shareWithEmail.value = ''
      updateSharedWithEmails(res.data.recipe)
    } catch (err) {
      toast.error(err.response.data.error)
    }
  }

  const unshareWithUser = async (email) => {
    if (!canShareWithUsers.value) {
      Swal.fire({
        title: 'Error',
        text: 'You are not authorized to share this recipe.',
        icon: 'error',
        confirmButtonColor: '#F28705'
      })
      return
    }
    let emails = [email]
    try {
      let res = await UserService.unshareRecipe(recipe.value.id, emails)
      toast.success("Unshared with " + email)
      updateSharedWithEmails(res.data.recipe)
    } catch (err) {
      toast.error(err.response.data.error)
    }
  }

  const specieTagsKey = (specie) => {
    return 'specie-tags-' + specie.id
  }
  const specieTagKey = (specie, tag) => {
    return specie.id + '-tag-' + tag.id
  }

  const goBack = () => {
    router.go(-1)
  }

  const goAdvancedEditor = () => {
    const i = Math.floor(Math.random() * pets.value.length)
    const pet = pets.value[i]
    router.push({ name: 'RecipeAdvancedEditForPet', params: { recipeId: recipe.value.id, petId: pet.id } })
  }

  const ingredientGramsDontMatchRecipe = computed({
    get: () => sumIngredientsGrams() != recipe.value.nutrition_breakdown.total_qty
  })

  const totalDisplay = computed({
      get: () => getTotalGramsText()
  })

  const userOwnsRecipe = computed({
    get: () => recipe.value.user_id == userStore.id
  })

  const canShareWithUsers = computed({
    get: () => userOwnsRecipe.value && (userStore.isAdmin || userStore.isProfessional)
  })
</script>
