import { computed, unref } from 'vue'

export function useRecipeModel(recipeRef) {
  const recipe = computed(() => unref(recipeRef) || { nutrition_breakdown: { total_qty: 2980 }, ingredients: []})

  // Helper functions
  const roundTenth = (value) => Math.round(value * 10) / 10
  const roundHundredth = (value) => Math.round(value * 100) / 100
  const roundToDecimals = (value, decimals) => Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)

  const nutrientNameFormatted = (nutrientName, withAsterisks = false) => {
    switch (nutrientName) {
      case 'Water': {
        return withAsterisks ? '****Total Water Content' : 'Total Water Content'
      }
      case 'EPA + DHA undifferentiated': {
        return 'Omega 3 EPA + DHA undifferentiated'
      }
      case 'Vitamin B-6': {
        return withAsterisks ? '*Vitamin B-6 (Pyridoxine)' : 'Vitamin B-6 (Pyridoxine)'
      }
      case 'Folate, total': {
        return withAsterisks ? '*Folate, Folic Acid, Vitamin B9 total' : 'Folate, Folic Acid, Vitamin B9 total'
      }
      case 'Iron, Fe':
      case 'Magnesium, Mg':
      case 'Potassium, K':
      case 'Zinc, Zn':
      case 'Copper, Cu':
      case 'Manganese, Mn':
      case 'Thiamin':
      case 'Riboflavin':
      case 'Niacin':
      case 'Pantothenic acid':
      case 'Choline, total':
      case 'Vitamin B-12':
      case 'Vitamin K (phylloquinone)':
      case 'Tryptophan':
      case 'Threonine':
      case 'Isoleucine':
      case 'Leucine':
      case 'Lysine':
      case 'Methionine':
      case 'Cystine':
      case 'Phenylalanine':
      case 'Tyrosine':
      case 'Valine':
      case 'Arginine':
      case 'Histidine': {
        return withAsterisks ? '*' + nutrientName : nutrientName
      }
      case 'Carbohydrate, by difference':
      case 'Fiber, total dietary':
      case 'Vitamin C, total ascorbic acid':
      case 'Betaine':
      case 'Retinol':
      case 'Carotene, beta':
      case 'Carotene, alpha':
      case 'Cryptoxanthin, beta':
      case 'Lycopene':
      case 'Lutein + zeaxanthin':
      case 'Fatty acids, total saturated':
      case 'Fatty acids, total monounsaturated':
      case 'Fatty acids, total polyunsaturated':
      case 'Fatty acids, total trans':
      case 'Fatty acids, total trans-monoenoic':
      case 'Cholesterol':
      case 'Alanine':
      case 'Aspartic acid':
      case 'Glutamic acid':
      case 'Glycine':
      case 'Proline':
      case 'Serine':
      case 'Hydroxyproline': {
        return withAsterisks ? '**' + nutrientName : nutrientName
      }
      case 'Chloride, Cl':
      case 'Omega 3a-linolenic acid (ALA, 18:3)':
      case 'Omega 6 Linoleic Acid (LA, 18:2)':
      case 'Omega 6 Arachidonic Acid (AA, 20:4)': {
        return withAsterisks ? '***' + nutrientName : nutrientName
      }
      case 'Taurine':
      case 'Oxalic acid': {
        return withAsterisks ? '**' + nutrientName + '***' : nutrientName
      }
      default: {
        return nutrientName
      }
    }
  }

  const ingredientNutrientAmount = (ingredient, nutrientName, nutrient = null) => {
    if (!nutrient) {
      nutrient = ingredient.nutrition_breakdown.nutrients.find(n => n.name == nutrientName)
    }
    if (!nutrient) return null

    let baseline_amount = ingredient.nutrition_breakdown.total_qty
    if (baseline_amount == 0) return 0
    let recipe_amount = ingredient.amount.total_g
    let multiplier = recipe_amount / baseline_amount
    let amount = 0
    try {
      amount = parseFloat(nutrient.amount.qty) * multiplier
    } catch (error) {
      return null
    }
    return amount
  }

  const ingredientNutrientAmountFormatted = (ingredient, nutrientName, nutrient = null) => {
    if (!nutrient) {
      nutrient = ingredient.nutrition_breakdown.nutrients.find(n => n.name == nutrientName)
    }
    if (!nutrient) return 'unknown'

    switch (nutrientName) {
      case 'Ca/P Ratio':
      case 'Ca/Vitamin C Ratio':
      case 'Zn:Cu ratio': {
        return ''
      }
      default: {
        let amount = ingredientNutrientAmount(ingredient, nutrientName, nutrient)
        if (amount == null) return 'unknown'
        return roundHundredth(amount).toString() + nutrient.amount.unit_type
      }
    }
  }

  // // Computed properties
  // const totalGrams = computed(() => nutritionBreakdown.value.total_qty)

  const ingredientsTotalGrams = computed(() => {
    return recipe.value.ingredients.reduce((sum, ingredient) => {
      return sum + parseFloat(ingredient.amount.total_g);
    }, 0);
  });

  const caPRatio = computed(() => parseFloat(nutrientByName('Calcium, Ca').amount.qty) / parseFloat(nutrientByName('Phosphorus, P').amount.qty))
  const caPRatioFormatted = () => roundTenth(caPRatio.value).toString() + ':1'

  const caVitaminCRatio = computed(() => parseFloat(nutrientByName('Calcium, Ca').amount.qty) / parseFloat(nutrientByName('Vitamin C, total ascorbic acid').amount.qty))
  const caVitaminCRatioFormatted = () => roundTenth(caVitaminCRatio.value).toString() + ':1'

  const znCuRatio = computed(() => parseFloat(nutrientByName('Zinc, Zn').amount.qty) / parseFloat(nutrientByName('Copper, Cu').amount.qty))
  const znCuRatioFormatted = () => roundTenth(znCuRatio.value).toString() + ':1'

  const kcalFromProtein = computed(() => parseFloat(nutrientByName('crude Protein').amount.qty) * 3.5)
  const kcalFromProteinRounded = computed(() => Math.round(kcalFromProtein.value))

  const kcalFromFat = computed(() => parseFloat(nutrientByName('Total lipid (fat)').amount.qty) * 8.5)
  const kcalFromFatRounded = computed(() => Math.round(kcalFromFat.value))

  const kcalFromCarbs = computed(() => parseFloat(nutrientByName('Carbohydrate, by difference').amount.qty) * 3.5)
  const kcalFromCarbsRounded = computed(() => Math.round(kcalFromCarbs.value))

  const kcalFromProteinPercentAsFedRounded = computed(() => Math.round((kcalFromProtein.value / (kcalFromProtein.value + kcalFromFat.value + kcalFromCarbs.value)) * 100))
  const kcalFromFatPercentAsFedRounded = computed(() => Math.round((kcalFromFat.value / (kcalFromProtein.value + kcalFromFat.value + kcalFromCarbs.value)) * 100))
  const kcalFromCarbsPercentAsFedRounded = computed(() => Math.round((kcalFromCarbs.value / (kcalFromProtein.value + kcalFromFat.value + kcalFromCarbs.value)) * 100))

  const ketoRatio = computed(() => kcalFromFat.value / (kcalFromProtein.value + kcalFromCarbs.value))
  const ketoRatioFormatted = () => roundTenth(ketoRatio.value).toString() + ':1'

  const omega3Amount = computed(() => parseFloat(nutrientByName('Omega 3a-linolenic acid (ALA, 18:3)').amount.qty) + parseFloat(nutrientByName('EPA + DHA undifferentiated').amount.qty))
  const omega6Amount = computed(() => parseFloat(nutrientByName('Omega 6 Arachidonic Acid (AA, 20:4)').amount.qty) + parseFloat(nutrientByName('Omega 6 Linoleic Acid (LA, 18:2)').amount.qty))
  const omega63Ratio = computed(() => {
    if (omega3Amount.value == 0) return 0
    return omega6Amount.value / omega3Amount.value
  })
  const omega63RatioFormatted = () => {
    return roundTenth(omega63Ratio.value).toString() + ':1'
  }

  const totalGramsDryMatter = computed(() => ingredientsTotalGrams.value - parseFloat(nutrientByName('Water').amount.qty))
  const totalGramsDryMatterRounded = computed(() => Math.round(totalGramsDryMatter.value))

  // const calciumDmb = computed({
  //   get: () => (nutrientByName('Calcium, Ca').amount.qty / 1000) / totalGramsDryMatter.value
  // })
  // const calciumDmbPercentRounded = computed({
  //   get: () => roundTenth(calciumDmb.value * 100)
  // })
  // const calciumDmRounded = computed({
  //   get: () => roundHundredth(totalGramsDryMatter.value * calciumDmb.value)
  // })

  const nutrientAsFed = (nutrientName, nutrient = null) => {
    if (!nutrient) {
      nutrient = nutrientByName(nutrientName)
    }
    if (!nutrient) {
      return null
    }
    switch (nutrientName) {
      case 'Water': {
        return (parseFloat(nutrient.amount.qty) / ingredientsTotalGrams.value) * 100
      }
      case 'Energy': {
        return (1000 / ingredientsTotalGrams.value) * parseFloat(nutrient.amount.qty)
      }
      case 'crude Protein':
      case 'Total lipid (fat)':
      case 'Carbohydrate, by difference':
      case 'Fiber, total dietary': {
        return (parseFloat(nutrient.amount.qty) / ingredientsTotalGrams.value) * 100
      }
      default: {
        let multiplier = 1
        if (nutrient.amount.unit_type == 'mg') {
          multiplier = 1000
        } else if (nutrient.amount.unit_type == 'µg' || nutrient.amount.unit_type == 'ug') {
          multiplier = 1000000
        }
        return ((parseFloat(nutrient.amount.qty) / multiplier) / ingredientsTotalGrams.value) * 100
      }
    }
  }

  const nutrientAsFedFormatted = (nutrientName, nutrient = null) => {
    if (!nutrient) {
      nutrient = nutrientByName(nutrientName)
    }
    if (!nutrient) {
      return ''
    }
    switch (nutrientName) {
      case 'Water': {
        return roundTenth(nutrientAsFed(nutrientName, nutrient)).toString() + '% moisture'
      }
      case 'Energy': {
        return Math.round(nutrientAsFed(nutrientName, nutrient)).toString() + 'kcal/kg'
      }
      case 'crude Protein':
      case 'Total lipid (fat)':
      case 'Carbohydrate, by difference':
      case 'Fiber, total dietary': {
        return roundTenth(nutrientAsFed(nutrientName, nutrient)).toString() + '%'
      }
      default: {
        let amount = nutrientAsFed(nutrientName, nutrient)
        if (amount == null) return ''
        if (amount < 0.0001) return '<0.0001%'
        else if (amount < 0.001) return roundToDecimals(amount, 4).toString() + '%'
        else return roundToDecimals(amount, 3).toString() + '%'
      }
    }
  }

  const nutrientDryMatterBasis = (nutrientName, nutrient = null) => {
    if (!nutrient) {
      nutrient = nutrientByName(nutrientName)
    }
    if (!nutrient) {
      return null
    }
    switch (nutrientName) {
      case 'Energy': {
        return (1000 / totalGramsDryMatter.value) * parseFloat(nutrient.amount.qty)
      }
      case 'Calcium, Ca': {
        return (parseFloat(nutrient.amount.qty) / 1000) / totalGramsDryMatter.value
      }
      case 'crude Protein':
      case 'Total lipid (fat)':
      case 'Carbohydrate, by difference':
      case 'Fiber, total dietary':
      case 'EPA + DHA undifferentiated':
      case 'Omega 3a-linolenic acid (ALA, 18:3)':
      case 'Omega 6 Linoleic Acid (LA, 18:2)':
      case 'Omega 6 Arachidonic Acid (AA, 20:4)':
      case 'Tryptophan':
      case 'Threonine':
      case 'Isoleucine':
      case 'Leucine':
      case 'Lysine':
      case 'Methionine':
      case 'Cystine':
      case 'Phenylalanine':
      case 'Tyrosine':
      case 'Valine':
      case 'Arginine':
      case 'Histidine': {
        return parseFloat(nutrient.amount.qty) / totalGramsDryMatter.value
      }
      case 'Iron, Fe':
      case 'Zinc, Zn':
      case 'Copper, Cu':
      case 'Manganese, Mn':
      case 'Thiamin':
      case 'Riboflavin':
      case 'Niacin':
      case 'Pantothenic acid':
      case 'Vitamin B-6':
      case 'Choline, total': {
        return parseFloat(nutrient.amount.qty) / (totalGramsDryMatter.value / 1000)
      }
      case 'Magnesium, Mg':
      case 'Phosphorus, P':
      case 'Potassium, K':
      case 'Sodium, Na':
      case 'Chloride, Cl': {
        return (parseFloat(nutrient.amount.qty) / 1000) / totalGramsDryMatter.value
      }
      case 'vitamin A': {
        return (3.3333 * parseFloat(nutrientByName('Retinol').amount.qty)) + (1.66 * parseFloat(nutrientByName('Carotene, beta').amount.qty)) + (1.66 * parseFloat(nutrientByName('Carotene, alpha').amount.qty)) + (1.66 * parseFloat(nutrientByName('Cryptoxanthin, beta').amount.qty)) / (totalGramsDryMatter.value / 1000)
      }
      case 'Selenium, Se':
      case 'Iodine, I':
      case 'Folate, total':
      case 'Vitamin B-12': {
        return (parseFloat(nutrient.amount.qty) / 1000) / (totalGramsDryMatter.value / 1000)
      }
      case 'Vitamin E (alpha-tocopherol)': {
        return 1.5 * parseFloat(nutrient.amount.qty) / (totalGramsDryMatter.value / 1000)
      }
      case 'Vitamin D3 (cholecalciferol)': {
        return 40 * parseFloat(nutrient.amount.qty) / (totalGramsDryMatter.value / 1000)
      }
      default: {
        return null
      }
    }
  }

  const nutrientDryMatterBasisFormatted = (nutrientName, nutrient = null) => {
    if (!nutrient) {
      nutrient = nutrientByName(nutrientName)
    }
    if (!nutrient) {
      return ''
    }
    switch (nutrientName) {
      case 'Energy': {
        return Math.round(nutrientDryMatterBasis(nutrientName)).toString() + 'kcal/ kg DM'
      }
      case 'Calcium, Ca': {
        return roundHundredth(nutrientDryMatterBasis(nutrientName, nutrient) * 100).toString() + '%'
      }
      case 'crude Protein':
      case 'Total lipid (fat)':
      case 'Carbohydrate, by difference':
      case 'Fiber, total dietary': {
        return roundHundredth(nutrientDryMatterBasis(nutrientName, nutrient) * 100).toString() + '%'
      }
      case 'Iron, Fe': {
        return roundHundredth(nutrientDryMatterBasis(nutrientName, nutrient)).toString() + 'mg/kg'
      }
      case 'Magnesium, Mg':
      case 'Phosphorus, P':
      case 'Potassium, K':
      case 'Sodium, Na':
      case 'Chloride, Cl':
      case 'EPA + DHA undifferentiated':
      case 'Omega 3a-linolenic acid (ALA, 18:3)':
      case 'Omega 6 Linoleic Acid (LA, 18:2)':
      case 'Omega 6 Arachidonic Acid (AA, 20:4)':
      case 'Tryptophan':
      case 'Threonine':
      case 'Isoleucine':
      case 'Leucine':
      case 'Lysine':
      case 'Methionine':
      case 'Cystine':
      case 'Phenylalanine':
      case 'Tyrosine':
      case 'Valine':
      case 'Arginine':
      case 'Histidine': {
        return roundHundredth(nutrientDryMatterBasis(nutrientName, nutrient) * 100).toString() + '%'
      }
      case 'Zinc, Zn': {
        return roundHundredth(nutrientDryMatterBasis(nutrientName, nutrient)).toString() + 'mg/kg'
      }
      case 'Copper, Cu':
      case 'Manganese, Mn':
      case 'Selenium, Se':
      case 'Iodine, I':
      case 'Thiamin':
      case 'Riboflavin':
      case 'Niacin':
      case 'Pantothenic acid':
      case 'Vitamin B-6':
      case 'Folate, total':
      case 'Choline, total':
      case 'Vitamin B-12': {
        return roundHundredth(nutrientDryMatterBasis(nutrientName, nutrient)).toString() + 'mg/kg'
      }
      case 'vitamin A': {
        return roundHundredth(nutrientDryMatterBasis(nutrientName, nutrient)).toString() + 'IU'
      }
      case 'Vitamin E (alpha-tocopherol)': {
        return Math.round(nutrientDryMatterBasis(nutrientName, nutrient)).toString() + 'IU'
      }
      case 'Vitamin D3 (cholecalciferol)': {
        return Math.round(nutrientDryMatterBasis(nutrientName, nutrient)).toString() + 'IU'
      }
      default: {
        return ''
      }
    }
  }

  const energyDryMatterBasis = computed({
    get: () => nutrientDryMatterBasis('Energy')
  })

  const dryMatterBasisMultiplier = computed(() => {
    if (energyDryMatterBasis.value <= 4000) return 1
    return energyDryMatterBasis.value / 4000
  })

  const dmbRangeClassByNutrientName = (nutrientName, nutrient = null, petLifePhase = "Adult", adjustForEnergyDmbOver4k = false) => {
    if (!nutrient) {
      nutrient = nutrientByName(nutrientName)
    }
    if (!nutrient) {
      return ''
    }

    let recipeDmb = null
    let dmbLowMax = null
    let dmbHighMin = null

    let multiplier = 1
    if (adjustForEnergyDmbOver4k) multiplier = dryMatterBasisMultiplier.value

    if (petLifePhase == 'Puppy') {
      dmbLowMax = nutrient.dmb_low_max_puppy === null ? null : roundHundredth(nutrient.dmb_low_max_puppy * multiplier)
      dmbHighMin = nutrient.dmb_high_min_puppy === null ? null : roundHundredth(nutrient.dmb_high_min_puppy * multiplier)
    } else {
      dmbLowMax = nutrient.dmb_low_max_adult_dog === null ? null : roundHundredth(nutrient.dmb_low_max_adult_dog * multiplier)
      dmbHighMin = nutrient.dmb_high_min_adult_dog === null ? null : roundHundredth(nutrient.dmb_high_min_adult_dog * multiplier)
    }
    if (dmbLowMax == null && dmbHighMin == null) return ''

    switch (nutrientName) {
      case 'Cystine': {
        recipeDmb = roundHundredth((nutrientDryMatterBasis(nutrientName, nutrient) + nutrientDryMatterBasis('Methionine')) * 100)
        break
      }
      case 'Tyrosine': {
        recipeDmb = roundHundredth((nutrientDryMatterBasis(nutrientName, nutrient) + nutrientDryMatterBasis('Phenylalanine')) * 100)
        break
      }
      case 'Energy':
      case 'Iron, Fe':
      case 'Zinc, Zn':
      case 'Copper, Cu':
      case 'Manganese, Mn':
      case 'Selenium, Se':
      case 'Iodine, I':
      case 'Thiamin':
      case 'Riboflavin':
      case 'Niacin':
      case 'Pantothenic acid':
      case 'Vitamin B-6':
      case 'Folate, total':
      case 'Choline, total':
      case 'Vitamin B-12':
      case 'vitamin A':
      case 'Vitamin E (alpha-tocopherol)':
      case 'Vitamin D3 (cholecalciferol)': {
        recipeDmb = roundHundredth(nutrientDryMatterBasis(nutrientName, nutrient))
        break
      }
      default: {
        recipeDmb = roundHundredth(nutrientDryMatterBasis(nutrientName, nutrient) * 100)
        break
      }
    }

    // console.log('nutrientName', nutrientName, 'recipeDmb', recipeDmb, 'dmbLowMax', dmbLowMax, 'dmbHighMin', dmbHighMin)

    if (dmbLowMax != null && recipeDmb <= dmbLowMax) return 'text-yellow-500'
    else if (dmbHighMin != null && recipeDmb >= dmbHighMin) return 'text-red-500'
    else return 'text-green-500'
  }

  const dmRangeClassByNutrientName = (nutrientName, nutrient = null, petLifePhase = "Adult", adjustForEnergyDmbOver4k = false) => {
    if (!nutrient) {
      nutrient = nutrientByName(nutrientName)
    }
    if (!nutrient) {
      return ''
    }

    let recipeDm = null
    let dmLowMax = null
    let dmHighMin = null

    let multiplier = 1
    if (adjustForEnergyDmbOver4k) multiplier = dryMatterBasisMultiplier.value

    if (petLifePhase == 'Puppy') {
      dmLowMax = nutrient.dm_low_max_puppy === null ? null : roundToDecimals(nutrient.dm_low_max_puppy * multiplier, 4)
      dmHighMin = nutrient.dm_high_min_puppy === null ? null : roundToDecimals(nutrient.dm_high_min_puppy * multiplier, 4)
    } else {
      dmLowMax = nutrient.dm_low_max_adult_dog === null ? null : roundToDecimals(nutrient.dm_low_max_adult_dog * multiplier, 4)
      dmHighMin = nutrient.dm_high_min_adult_dog === null ? null : roundToDecimals(nutrient.dm_high_min_adult_dog * multiplier, 4)
    }
    if (dmLowMax == null && dmHighMin == null) return ''

    switch (nutrientName) {
      case 'Cystine': {
        recipeDm = roundHundredth(nutrientDryMatterPer1000Kcal(nutrientName, nutrient) + nutrientDryMatterPer1000Kcal('Methionine'))
        break
      }
      case 'Tyrosine': {
        recipeDm = roundHundredth(nutrientDryMatterPer1000Kcal(nutrientName, nutrient) + nutrientDryMatterPer1000Kcal('Phenylalanine'))
        break
      }
      case 'Vitamin B-12': {
        recipeDm = roundToDecimals(nutrientDryMatterPer1000Kcal(nutrientName, nutrient), 4)
        break
      }
      default: {
        recipeDm = roundHundredth(nutrientDryMatterPer1000Kcal(nutrientName, nutrient))
        break
      }
    }

    // console.log('nutrientName', nutrientName, 'recipeDm', recipeDm, 'dmLowMax', dmLowMax, 'dmHighMin', dmHighMin)

    if (dmLowMax != null && recipeDm <= dmLowMax) return 'text-yellow-500'
    else if (dmHighMin != null && recipeDm >= dmHighMin) return 'text-red-500'
    else return 'text-green-500'
  }

  const nutrientDryMatterPer1000Kcal = (nutrientName, nutrient = null) => {
    if (!nutrient) {
      nutrient = nutrientByName(nutrientName)
    }
    if (!nutrient) {
      return null
    }
    switch (nutrientName) {
      case 'crude Protein':
      case 'Total lipid (fat)':
      case 'Calcium, Ca': {
        return dryMatterPer1000Kcal() * nutrientDryMatterBasis(nutrientName, nutrient)
      }
      case 'Iron, Fe':
      case 'Zinc, Zn':
      case 'Copper, Cu':
      case 'Manganese, Mn':
      case 'Selenium, Se':
      case 'Iodine, I':
      case 'Thiamin':
      case 'Riboflavin':
      case 'Niacin':
      case 'Pantothenic acid':
      case 'Vitamin B-6':
      case 'Folate, total':
      case 'Choline, total':
      case 'Vitamin B-12':
      case 'vitamin A':
      case 'Vitamin E (alpha-tocopherol)':
      case 'Vitamin D3 (cholecalciferol)': {
        return (dryMatterPer1000Kcal() / 1000) * nutrientDryMatterBasis(nutrientName, nutrient)
      }
      case 'Magnesium, Mg':
      case 'Phosphorus, P':
      case 'Potassium, K':
      case 'Sodium, Na':
      case 'Chloride, Cl':
      case 'EPA + DHA undifferentiated':
      case 'Omega 3a-linolenic acid (ALA, 18:3)':
      case 'Omega 6 Linoleic Acid (LA, 18:2)':
      case 'Omega 6 Arachidonic Acid (AA, 20:4)':
      case 'Tryptophan':
      case 'Threonine':
      case 'Isoleucine':
      case 'Leucine':
      case 'Lysine':
      case 'Methionine':
      case 'Cystine':
      case 'Phenylalanine':
      case 'Tyrosine':
      case 'Valine':
      case 'Arginine':
      case 'Histidine': {
        return dryMatterPer1000Kcal() * nutrientDryMatterBasis(nutrientName, nutrient)
      }
      default: {
        return null
      }
    }
  }

  const nutrientDryMatterPer1000KcalFormatted = (nutrientName, nutrient = null) => {
    if (!nutrient) {
      nutrient = nutrientByName(nutrientName)
    }
    if (!nutrient) {
      return ''
    }
    switch (nutrientName) {
      case 'Calcium, Ca': {
        return roundHundredth(nutrientDryMatterPer1000Kcal(nutrientName, nutrient)).toString() + 'g'
      }
      case 'crude Protein':
      case 'Total lipid (fat)': {
        return roundHundredth(nutrientDryMatterPer1000Kcal(nutrientName, nutrient)).toString() + 'g'
      }
      case 'Iron, Fe':
      case 'Zinc, Zn':
      case 'Copper, Cu':
      case 'Manganese, Mn':
      case 'Selenium, Se':
      case 'Iodine, I': {
        return roundHundredth(nutrientDryMatterPer1000Kcal(nutrientName, nutrient)).toString() + 'mg'
      }
      case 'Magnesium, Mg':
      case 'Phosphorus, P':
      case 'Potassium, K':
      case 'Sodium, Na':
      case 'Chloride, Cl':
      case 'Tryptophan':
      case 'Threonine':
      case 'Isoleucine':
      case 'Leucine':
      case 'Lysine':
      case 'Methionine':
      case 'Cystine':
      case 'Phenylalanine':
      case 'Tyrosine':
      case 'Valine':
      case 'Arginine':
      case 'Histidine': {
        return roundHundredth(nutrientDryMatterPer1000Kcal(nutrientName, nutrient)).toString() + 'g'
      }
      case 'Thiamin':
      case 'Riboflavin':
      case 'Niacin':
      case 'Pantothenic acid':
      case 'Vitamin B-6':
      case 'Folate, total':
      case 'Choline, total': {
        return roundHundredth(nutrientDryMatterPer1000Kcal(nutrientName, nutrient)).toString() + 'mg'
      }
      case 'Vitamin B-12': {
        return roundToDecimals(nutrientDryMatterPer1000Kcal(nutrientName, nutrient), 4).toString() + 'mg'
      }
      case 'vitamin A': {
        return roundHundredth(nutrientDryMatterPer1000Kcal(nutrientName, nutrient)).toString() + 'IU'
      }
      case 'Vitamin E (alpha-tocopherol)':
      case 'Vitamin D3 (cholecalciferol)': {
        return roundHundredth(nutrientDryMatterPer1000Kcal(nutrientName, nutrient)).toString() + 'IU'
      }
      case 'EPA + DHA undifferentiated':
      case 'Omega 3a-linolenic acid (ALA, 18:3)':
      case 'Omega 6 Linoleic Acid (LA, 18:2)':
      case 'Omega 6 Arachidonic Acid (AA, 20:4)': {
        return roundHundredth(nutrientDryMatterPer1000Kcal(nutrientName, nutrient)).toString() + 'g'
      }
      default: {
        return ''
      }
    }
  }

  const dryMatterPer1000Kcal = () => {
    let dmb = (1000 / totalGramsDryMatter.value) * parseFloat(nutrientByName('Energy').amount.qty)
    return 1000000 / dmb
  }
  const dryMatterPer1000KcalFormatted = () => Math.round(dryMatterPer1000Kcal()).toString() + 'g'
  // const nutrientDryMatterPer1000KcalFormatted = (nutrientName, nutrient = null) => {
  //   if (!nutrient) {
  //     nutrient = nutrientByName(nutrientName)
  //   }
  //   if (!nutrient) {
  //     return ''
  //   }
  //   switch (nutrientName) {
  //     case 'Energy': {
  //       return (1000 / totalGramsDryMatter.value) * parseFloat(nutrient.amount.qty)
  //     }
  //     case 'Calcium, Ca': {
  //       return (nutrient.amount.qty / 1000) / totalGramsDryMatter.value
  //     }
  //     default: {
  //       return null
  //     }
  //   }
  // }
  // const dryMatterBasisPercentByNutrientName = (nutrientName) => {
  //   switch (nutrientName) {
  //     case 'Energy': {
  //       Math.round((1000 / batchTotalGramsDryMatter.value) * batchNutrient('Energy').amount.qty)
  //     }
  //     case 'Calcium, Ca': {
  //       return roundTenth(nutrientDryMatterBasis(nutrientName) * 100)
  //     }
  //     default: {
  //       return null
  //     }
  //   }
  // }

  const nutrientByName = (name) => recipe.value.nutrition_breakdown.nutrients.find(n => n.name === name)

  const nutrientsByCategoryName = (categoryName) => recipe.value.nutrition_breakdown.nutrients.filter(n => n.category_name === categoryName)

  const nutrientTotalFormatted = (nutrientName, nutrient = null) => {
    switch (nutrientName) {
      case 'Ca/P Ratio': {
        return caPRatioFormatted()
      }
      case 'Ca/Vitamin C Ratio': {
        return caVitaminCRatioFormatted()
      }
      case 'Zn:Cu ratio': {
        return znCuRatioFormatted()
      }
      case 'Omega 6:3 ratio': {
        return omega63RatioFormatted()
      }
      case 'EPA + DHA undifferentiated':
      case 'Omega 3a-linolenic acid (ALA, 18:3)':
      case 'Omega 6 Linoleic Acid (LA, 18:2)':
      case 'Omega 6 Arachidonic Acid (AA, 20:4)': {
        if (!nutrient) {
          nutrient = recipe.value.nutrition_breakdown.nutrients.find(n => n.name == nutrientName)
        }
        if (!nutrient) {
          return ''
        }
        return roundHundredth(nutrient.amount.qty).toString() + nutrient.amount.unit_type
      }
      default: {
        if (!nutrient) {
          nutrient = recipe.value.nutrition_breakdown.nutrients.find(n => n.name == nutrientName)
        }
        if (!nutrient) {
          return ''
        }
        // switch (nutrientName) {
        //   case 'Energy': {
        //     return Math.round(nutrient.amount.qty).toString() + nutrient.amount.unit_type
        //   }
        //   default: {
        //     return nutrient.amount.qty.toString() + nutrient.amount.unit_type
        //   }
        // }
        return Math.round(nutrient.amount.qty).toString() + nutrient.amount.unit_type
      }
    }
  }

  const ingredientGramsDryMatterFormatted = (ingredient) => {
    const totalGrams = parseFloat(ingredient.amount.total_g);
    const waterNutrient = ingredient.nutrition_breakdown.nutrients.find(n => n.name === 'Water');
    
    if (waterNutrient) {
      // not entirely sure what's going on here, used ai and just verified results are correct
      const waterGrams = (waterNutrient.amount.qty / 100) * totalGrams;
      return roundHundredth(((totalGrams - waterGrams) * 100) / 100).toString() + 'g';
    } else {
      return roundHundredth(totalGrams).toString() + 'g'; // Return total grams if water content is not available
    }
  }

  return {
    nutrientNameFormatted,
    ingredientNutrientAmountFormatted,
    ingredientsTotalGrams,
    caPRatio,
    caPRatioFormatted,
    caVitaminCRatioFormatted,
    znCuRatioFormatted,
    kcalFromProtein,
    kcalFromProteinRounded,
    kcalFromFat,
    kcalFromFatRounded,
    kcalFromCarbs,
    kcalFromCarbsRounded,
    kcalFromProteinPercentAsFedRounded,
    kcalFromFatPercentAsFedRounded,
    kcalFromCarbsPercentAsFedRounded,
    ketoRatioFormatted,
    omega63RatioFormatted,
    totalGramsDryMatter,
    totalGramsDryMatterRounded,
    nutrientAsFedFormatted,
    nutrientDryMatterBasis,
    nutrientDryMatterBasisFormatted,
    dmbRangeClassByNutrientName,
    dmRangeClassByNutrientName,
    nutrientDryMatterPer1000Kcal,
    nutrientDryMatterPer1000KcalFormatted,
    dryMatterPer1000KcalFormatted,
    nutrientByName,
    nutrientsByCategoryName,
    nutrientTotalFormatted,
    ingredientGramsDryMatterFormatted,
    // totalGrams,
    // totalDryMatter,
    // proteinPercentage,
    // ... return other properties and methods
  }
}
