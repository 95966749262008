import Api from '../apis/api'

class RecipeService {
  createRecipe (data) {
    return Api.post('api/recipes', data)
  }

  getRecipe (id) {
    return Api.get('api/recipes/' + id)
  }

  getRecipeWithNutrition (id) {
    return Api.get('api/recipes/' + id + '?include_all_nutrition_breakdowns=true')
  }

  getRecipeWithNutritionAndIngredientPrices (id) {
    return Api.get('api/recipes/' + id + '?include_all_nutrition_breakdowns=true&include_ingredient_prices=true')
  }

  getRecipeWithNutritionAndIngredientPricesAndParentAndChildren (id) {
    return Api.get('api/recipes/' + id + '?include_all_nutrition_breakdowns=true&include_ingredient_prices=true&include_parent_and_child_recipes=true')
  }

  getRecipeWithNutritionAndHiddenNutrients (id) {
    return Api.get('api/recipes/' + id + '?include_all_nutrition_breakdowns=true&include_hidden_nutrients=true')
  }

  getRecipeWithRecipeSteps (id) {
    return Api.get('api/recipes/' + id + '?include_recipe_steps=true')
  }

  getRecipeWithRecipeStepsAndParentAndChildren (id) {
    return Api.get('api/recipes/' + id + '?include_recipe_steps=true&include_parent_and_child_recipes=true')
  }

  listRecipes () {
    return Api.get('api/recipes')
  }

  listSpecieRecipes (specieSlug) {
    return Api.get('api/recipes?specie=' + specieSlug)
  }

  listDogRecipesCategory (tagSlug) {
    return Api.get('api/recipes?dog_recipe_tag_slug=' + tagSlug)
  }

  listSuggestedDogRecipesCategory (tagSlug) {
    return Api.get('api/recipes?suggested_only=true&dog_recipe_tag_slug=' + tagSlug)
  }

  listSuggestedSpecieRecipesCategory (specieSlug, tagSlug) {
    return Api.get('api/recipes?suggested_only=true&specie=' + specieSlug + '&specie_recipe_tag=' + tagSlug)
  }

  listMyRecipes () {
    return Api.get('api/my-recipes')
  }

  listSpecieMyRecipes (specieSlug) {
    return Api.get('api/my-recipes?specie=' + specieSlug)
  }

  listSuggestedRecipes (page = 1, size = undefined) {
    return Api.get('api/suggested-recipes', {
      params: {
        page,
        size
      }
    })
  }

  listSpecieSuggestedRecipes (specieSlug, page = 1, size = undefined) {
    return Api.get('api/suggested-recipes', {
      params: {
        specie: specieSlug,
        page,
        size
      }
    })
  }

  listInspiredRecipes (page = 1, size = undefined) {
    return Api.get('api/inspired-recipes', {
      params: {
        page,
        size
      }
    })
  }

  listSpecieInspiredRecipes (specieSlug, page = 1, size = undefined) {
    return Api.get('api/inspired-recipes', {
      params: {
        specie: specieSlug,
        page,
        size
      }
    })
  }

  searchRecipes (query) {
    return Api.get('api/recipes?query=' + query)
  }

  updateRecipe (recipeId, data) {
    return Api.post('api/recipes/' + recipeId + '?_method=PUT', data)
  }

  updateRecipeIngredients (recipeId, data) {
    return Api.post('api/recipes/' + recipeId + '/update_ingredients', data)
  }

  addIngredients (recipeId, data) {
    return Api.post('api/recipes/' + recipeId + '/add_ingredients', data)
  }

  duplicateRecipe (recipeId, name) {
    return Api.post('api/duplicate-recipes/' + recipeId, { name: name })
  }

  destroyRecipe (recipeId) {
    return Api.delete('api/recipes/' + recipeId);
  }

  adminListAllSharedRecipes () {
    return Api.get('api/admin/all-shared-recipes')
  }

  adminToggleSharedRecipeApproval (recipeId) {
    return Api.post('api/admin/toggle-shared-recipe-approval/' + recipeId)
  }
}

export default new RecipeService()
